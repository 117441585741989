import React from "react";
import {useState, useEffect} from 'react';
import {useNavigate} from "react-router-dom";
import Userfront from "@userfront/toolkit/react";
import { Card, CardHeader, CardBody, Box, Text, Heading, Center, Button, Image, Progress, Wrap, WrapItem, CloseButton, Input, Textarea, FormErrorMessage, FormControl, FormLabel } from '@chakra-ui/react'
import {useFormik} from 'formik';
import * as Yup from 'yup';
import { useMediaQuery } from '@chakra-ui/react'; // Import necessary components
import { Helmet } from 'react-helmet';


const Dashboard = () => {
    const navigate = useNavigate();
    const [selectedImage, setSelectedImage] = useState([]);
    const [presignedUrls, setPresignedUrls] = useState([]);
    const [showLoader, setShowLoader] = useState(false);
    const [requestUUID, setRequestUUID] = useState('abrakadabra');
    const [charCount, setCharCount] = useState(0);
    const FILE_SIZE = 500000; // 500KB
    const [isSmallScreen] = useMediaQuery("(max-width: 30em)"); // Small screen (less than 30em)
  
    useEffect(() => {
      // navigate when we gen request uuid from backend, files will be uploaded async
      if (requestUUID==='abrakadabra') {
        navigate("/uzklausa");
      } else {
        navigate(`/apmokejimas?request=${requestUUID}`);
      }
    }, [requestUUID]);
  
    const recalculate = e => {
      // console.log("event value:", e);
      setCharCount(e.target.value.length);
    };
  
    const validateImage = (size) => {
      if (size > FILE_SIZE) {
        // console.log('per didelis')
        // TODO: alertas neveikia ir padaryti downsizinga jei per didelis, o ne rasyti useriui errorus
        return { alert: "Max file size exceeded." };
      }
    };
  
    const formik = useFormik({
      initialValues: {
        requestName: '',
        requestText: '',
        files: []
      },
      validationSchema: Yup.object({
        files: Yup.mixed()
        .test("fileCountMax", "Per daug nuotraukų", (value) => {
            if(value && value.length>10){
              return false
            } else {
              return true
            }
           })
        .test("fileCount", "Prisekite daugiau nuotraukų", (value) => {
            if(value && value.length>0){
              return true
            } else {
              return false
            }
           }),
        requestName: Yup.string()
          .min(5, 'Per trumpas pavadinimas')
          .required('Trūksta pavadinimo'),
        requestText: Yup.string()
          .max(1000, 'Ne daugiau kaip 1000 simbolių!')
          .required('Trūksta užklausos teksto'),
      }),
      onSubmit: values => {
        setShowLoader(true);
        // console.log(values);
        let fileNames = [];
        values.files.forEach(file => {
          fileNames.push(file.name);
        });
        // preparing JSON file for upload
        const requestObject = {requestName: values.requestName, requestText: values.requestText}
        const requestJson = JSON.stringify(requestObject);
        const blob = new Blob([requestJson], { type: 'text/json' });
        const filesToUpload = [...selectedImage];
        filesToUpload.push(blob);
        fileNames.push("request.json");
        // Cia baigesi JSONO failo gamyba
        // console.log('https://sckumwyv6l.execute-api.eu-central-1.amazonaws.com/request-submit?filenames=['+fileNames.join(',')+']');
        let obj = {filenames: fileNames};
        const url = new URL(`https://sckumwyv6l.execute-api.eu-central-1.amazonaws.com/request-submit`);
        url.search = new URLSearchParams(obj);
        fetch(url, {
          headers: {Authorization: 'Bearer ' + Userfront.tokens.accessToken, mode: "no-cors"}})
           .then((res) => res.json())
           .then((data) => {
              // console.log(data);
              setPresignedUrls(data);
              //uuid is set by backend and it needs to be extracted from presigned URLs
              setRequestUUID(data[0].split('/')[4]);
              let index = 0;
              data.forEach(presignedUrl => {
                fetch(presignedUrl, {
                  method: 'PUT',
                  body: filesToUpload[index]
                })
                // .then(
                //   (success) => {
                //     console.log(success);
                //   }
                // )
                .catch(
                  error => console.dirxml(error.message) // Handle the error response object
                );
                index += 1;
              });
           })
           .catch((err) => {
              console.log(err.message);
           });
      },
    });
  
    return (
      <div>
        <Helmet>
          <title>Ką rengtis pagal Viliją Rušinskaitę - Nauja užklausa</title>
        </Helmet>
        <Box minH='2vw' />
        <Center>
        <Card border='1px' maxWidth="80vw" minWidth='60vw'>
        <CardHeader sx={{pb: ['0px','0px','0px','10px']}}>
            <Center>
            <Heading sx={{ fontSize: ['24px', '32px', '32px', '32px'], fontFamily: 'Montserrat, sans-serif',
      textAlign: 'center'
    }}>Nauja užklausa</Heading>
            </Center>
        </CardHeader>
        <CardBody>
            <Box>
            <form onSubmit={formik.handleSubmit}>
         <FormControl isInvalid={formik.touched.requestName && formik.errors.requestName} >
        <Input
           id="requestName"
           type="text"
           placeholder="Užklausos pavadinimas"
           {...formik.getFieldProps('requestName')}
         />
         <FormErrorMessage>{formik.errors.requestName}</FormErrorMessage>
         </FormControl>
         <Box minH='0.01vw' />
          <br/>
          <FormControl isInvalid={formik.touched.requestText && formik.errors.requestText}>
          <Textarea id="requestText" maxLength="1000" rows="10" cols="64" placeholder="Užklausos tekstas" spellcheck="false" onChange={recalculate} {...formik.getFieldProps('requestText')} />
         <Text fontSize='xs' align='right' color='gray'>{`${formik.values.requestText.length}/1000`}</Text>
         <FormErrorMessage>{formik.errors.requestText}</FormErrorMessage>
         </FormControl>
        <br></br>
        <Text fontSize='s'>Įkelti iki 10 nuotraukų</Text>
        <Box minH='1vw' />
        <FormControl isInvalid={formik.touched.files && formik.errors.files}>
        <Center>
        <Box 
        border='1px' 
        width="full" 
        borderRadius='6px'
        borderColor='#E2E8F0'
        >
        <Wrap justify='center' spacing='24px'>
        {selectedImage[0] && (Array.from(selectedImage).map((picture, index) => {
          return(
            <WrapItem key={`${picture.name}-${index}`}>
            <Card>
                <CardBody>
            <Image
              objectFit='cover'
              maxW={{ base: '100%', sm: '180px' }}
              alt="not found"
              src={URL.createObjectURL(selectedImage[index])}
            />
            <CloseButton position="absolute" right="1.5em" top="1.5em" size='lg' type='button' onClick={() => {
              // console.log('removed');
              const newFileArray = [...selectedImage];
              newFileArray.splice(index, 1);
              setSelectedImage(newFileArray);
              formik.setFieldValue("files", newFileArray);
            }}/>
            </CardBody>
            </Card>
            </WrapItem>
          )
        }))}
        </Wrap>
        <FormLabel 
          className='photo_uploader'
          htmlFor="filePicker" 
          sx={{ 
            padding: "20px 20px", 
            margin: "20px 20px", 
            fontFamily: 'Montserrat, sans-serif', 
            fontWeight: 'bold',
            fontSize: ['lg', 'ml', 'ml', 'ml'],
            borderColor: 'black',
            borderRadius: '8px', 
            display: 'flex', 
            justifyContent: 'center', 
            alignItems: 'center', 
            textAlign: 'center',
            colorScheme: 'white',
            color: 'black.500',
            borderWidth: '2px',
          }}
          _hover={{ cursor: 'pointer' }}
        >
          + Įkelti nuotraukas
        </FormLabel>
        <Input
        // do not display default "select files" button
          style= {{display: 'none'}}
          id="filePicker"
          type="file"
          name="files"
          accept="capture=camera,image/*"
          onChange={(event) => {
            const newFileArray = [...selectedImage];
            newFileArray.push(...event.target.files);
            setSelectedImage(newFileArray);
            formik.setFieldValue("files", newFileArray);
            if (Array.isArray(event.target.files)){
              // TODO: validateImage reikia passinti visus failus ir juos atskirai validuoti, o ne pirma
              validateImage(event.target.files[0].size)
            }
          }} 
          multiple
        />
         </Box>
         </Center>
         <FormErrorMessage>{formik.errors.files}</FormErrorMessage>
         </FormControl>
         
         <br/>
         {!isSmallScreen ? (<br/>) : null}
         <Button type="submit" bg='#FB8FFF' color='black' width="full"
         sx={{
          bg: "rgba(251, 143, 255, 0.7)",
          '&:hover': {
            bg: "rgba(251, 143, 255, 1)"
          },
         }}
         >Patvirtinti ir apmokėti</Button>
         <Box minH='2vw' />
         {showLoader? <Progress size='xs' colorScheme='cyan' isIndeterminate sx={{ bg: '#FB8FFF' }}/> : null }
       </form>
            </Box>
        </CardBody>
        </Card>
        </Center>
        <Box minH='2vw' />
      </div>
    );
  }
  
export default Dashboard;