import React from "react";
import {useState, useEffect} from 'react';
import Userfront from "@userfront/toolkit/react";
import { useFormik } from 'formik';
import * as Yup from 'yup';
import {useNavigate} from "react-router-dom";
import { Card, CardHeader, CardBody, Grid, GridItem, VStack, Progress, Avatar, Popover, PopoverTrigger, PopoverContent, PopoverHeader, PopoverBody, PopoverArrow, PopoverCloseButton, Tabs, TabList, TabPanels, Tab, TabPanel, Badge, Box, Modal, ModalOverlay, ModalContent, ModalCloseButton, ModalBody, useDisclosure, Stat, StatHelpText, Skeleton, SkeletonText, Divider, Text, Heading, useToast, Center, Button, Image, Stack, Wrap, WrapItem, CloseButton, Input, Textarea, FormErrorMessage, FormControl, FormLabel, AccordionIcon, AccordionButton, AccordionItem, Accordion, AccordionPanel } from '@chakra-ui/react'
import { Helmet } from 'react-helmet';


const TENANT = 'wbmg59gb';

// TODO: perziureti kodel backendas neatiduoda visu paveiksliuku  generatePresignedURLsForResponsePics GET /response-pics
// TODO: answered_requests ir unanswered_requests sharina daug kodo, perrasyti naudojant normalu if'a ir sumazinant kodo kartojima

const AnswerForm = React.memo(({ 
    requestObject, 
    setShowLoader, 
    showLoader, 
    setRequestUUID, 
    setRequestUserUuid, 
    hasLoadedAnswered,
    setUnansweredRequests,
    setAnsweredRequests   
}) => {
    const formKey = `form-${requestObject.requestUuid}`;
    
    const [selectedImage, setSelectedImage] = useState([]);
    const [presignedUrls, setPresignedUrls] = useState([]);
    const [charCount, setCharCount] = useState(0);
    
    const FILE_SIZE = 500000; // 500KB

    const validateImage = (size) => {
        if (size > FILE_SIZE) {
            // console.log('per didelis')
            // TODO: alertas neveikia ir padaryti downsizinga jei per didelis, o ne rasyti useriui errorus
            return { alert: "Max file size exceeded." };
        }
    };

    const formik = useFormik({
        initialValues: {
            responseText: '',
            files: []
        },
        validationSchema: Yup.object({
            files: Yup.mixed()
            .test("fileCount", "Prisegti daugiau nuotraukų", (value) => {
                if(value && value.length>0){
                  return true
                } else {
                  return false
                }
               }),
            responseText: Yup.string()
              .required('Trūksta užklausos teksto'),
        }),
        onSubmit: values => {
            setShowLoader(true);
            setRequestUUID(requestObject.requestUuid);
            setRequestUserUuid(requestObject.userUuid);
            
            // Create response.json first
            const responseObject = {responseText: values.responseText};
            const responseJson = JSON.stringify(responseObject);
            const blob = new Blob([responseJson], { type: 'application/json' });
            
            // Keep original filenames
            const fileNames = selectedImage.map(file => file.name);
            const responseFileName = `response.json`;
            fileNames.push(responseFileName);

            // Create URL exactly like in Dashboard.js
            let obj = {
                filenames: fileNames,
                request: requestObject.requestUuid,
                user: requestObject.userUuid
            };
            const url = new URL(`https://sckumwyv6l.execute-api.eu-central-1.amazonaws.com/answer-submit`);
            url.search = new URLSearchParams(obj);

            fetch(url, {
                headers: {Authorization: 'Bearer ' + Userfront.tokens.accessToken, mode: "no-cors"}
            })
            .then((res) => res.json())
            .then((data) => {
                // console.log(data);
                const filesToUpload = [...selectedImage, blob];
                let index = 0;
                
                // Create an array of promises for all uploads
                const uploadPromises = data.map((presignedUrl, index) => {
                    return fetch(presignedUrl, {
                        method: 'PUT',
                        body: filesToUpload[index]
                    });
                });

                // Wait for all uploads to complete
                return Promise.all(uploadPromises);
            })
            .then(() => {
                // console.log('All files uploaded successfully');
                // Update both request lists
                setUnansweredRequests(prev => 
                    prev.filter(req => req.requestUuid !== requestObject.requestUuid)
                );
                if (hasLoadedAnswered) {
                    setAnsweredRequests(prev => [
                        { ...requestObject, requestFulfilled: true, responseText: values.responseText },
                        ...prev
                    ]);
                }
                // Reset form state
                setSelectedImage([]);
                setPresignedUrls([]);
                setCharCount(0);
                formik.resetForm();
                setShowLoader(false);
            })
            .catch((err) => {
                console.log(err.message);
                setShowLoader(false);
            });
        },
    });
    
    // Update charCount whenever formik's responseText value changes
    useEffect(() => {
        setCharCount(formik.values.responseText.length);
    }, [formik.values.responseText]);

    return (
        <AccordionItem key={formKey}>
            <h2>
                <AccordionButton pl='0'>
                    <Box as="span" flex='1' textAlign='left'>
                        <Text fontSize='ml'>
                            Atsakymo forma
                        </Text>
                    </Box>
                    <AccordionIcon fontSize='24px' />
                </AccordionButton>
            </h2>
            <AccordionPanel pb={4}>
                <div>
                    <Center>
                        <Card maxWidth="100vw" minWidth='75vw' borderColor='transparent' variant='unstyled'>
                            <CardBody>
                                <Box>
                                    <form onSubmit={formik.handleSubmit} id={formKey}>
                                        <Box minH='0.01vw' />
                                        <FormControl isInvalid={formik.touched.responseText && formik.errors.responseText}>
                                            <Textarea 
                                                id="responseText" 
                                                maxLength="10000" 
                                                rows="10" 
                                                cols="64" 
                                                placeholder="Atsakymas" 
                                                spellCheck="false" 
                                                {...formik.getFieldProps('responseText')}  // Remove separate onChange
                                            />
                                            <Text fontSize='xs' align='right' color='gray'>
                                                {charCount}
                                            </Text>
                                            <FormErrorMessage>{formik.errors.responseText}</FormErrorMessage>
                                        </FormControl>
                                        <Box minH='1vw' />
                                        <FormControl isInvalid={formik.touched.files && formik.errors.files}>
                                            <Center>
                                                <Box border='1px' width="full">
                                                    <Wrap justify='center' spacing='24px'>
                                                        {selectedImage[0] && (Array.from(selectedImage).map((picture, index) => {
                                                            return(
                                                                    <WrapItem key={`${picture.name}-${index}`}>
                                                                        <Card>
                                                                            <CardBody>
                                                                                <Image
                                                                                    objectFit='cover'
                                                                                    maxW={{ base: '100%', sm: '180px' }}
                                                                                    alt="not found"
                                                                                    src={URL.createObjectURL(selectedImage[index])}
                                                                                />
                                                                                <CloseButton position="absolute" right="1.5em" top="1.5em" size='lg' type='button' onClick={() => {
                                                                                    // console.log('removed');
                                                                                    const newFileArray = [...selectedImage];
                                                                                    newFileArray.splice(index, 1);
                                                                                    setSelectedImage(newFileArray);
                                                                                    formik.setFieldValue("files", newFileArray);
                                                                                }}/>
                                                                            </CardBody>
                                                                        </Card>
                                                                    </WrapItem>
                                                            )
                                                        }))}
                                                    </Wrap>
                                                    <FormLabel 
                                                        className='photo_uploader'
                                                        htmlFor={`filePicker-${requestObject.requestUuid}`} 
                                                        sx={{ 
                                                          padding: "20px 20px", 
                                                          margin: "20px 20px", 
                                                          fontFamily: 'Montserrat, sans-serif', 
                                                          fontWeight: 'bold',
                                                          fontSize: ['lg', 'ml', 'ml', 'ml'],
                                                          borderColor: 'black',
                                                          borderRadius: '8px', 
                                                          display: 'flex', 
                                                          justifyContent: 'center', 
                                                          alignItems: 'center', 
                                                          textAlign: 'center',
                                                          colorScheme: 'white',
                                                          color: 'black.500',
                                                          borderWidth: '2px',
                                                        }}
                                                        _hover={{ cursor: 'pointer' }}
                                                    >
                                                        <Center>+ Įkelti nuotraukas</Center>
                                                    </FormLabel>
                                                    <Input
                                                        id={`filePicker-${requestObject.requestUuid}`} 
                                                        style={{display: 'none'}}
                                                        type="file"
                                                        name="files"
                                                        accept="capture=camera,image/*"
                                                        onChange={(event) => {
                                                            const newFileArray = [...selectedImage];
                                                            newFileArray.push(...event.target.files);
                                                            setSelectedImage(newFileArray);
                                                            formik.setFieldValue("files", newFileArray);
                                                            if (Array.isArray(event.target.files)){
                                                                // TODO: validateImage reikia passinti visus failus ir juos atskirai validuoti, o ne pirma
                                                                validateImage(event.target.files[0].size)
                                                            }
                                                        }} 
                                                        multiple
                                                    />
                                                </Box>
                                            </Center>
                                            <FormErrorMessage>{formik.errors.files}</FormErrorMessage>
                                        </FormControl>
                                        <br/>
                                        <Button type="submit"  
                                        bg='#FB8FFF' color='black' width="full"
                                        sx={{
                                         bg: "rgba(251, 143, 255, 0.7)",
                                         '&:hover': {
                                           bg: "rgba(251, 143, 255, 1)"
                                         },
                                        }}
                                        onClick={() => setRequestUUID(requestObject.requestUuid)}>Išsaugoti</Button>
                                        <Box minH='1vw' />
                                        {showLoader ? <Progress size='xs' isIndeterminate colorScheme='cyan' sx={{ bg: '#FB8FFF' }}/> : null }
                                    </form>
                                </Box>
                            </CardBody>
                        </Card>
                    </Center>
                </div>
            </AccordionPanel>
        </AccordionItem>
    );
});

const ReplySection = ({ 
    requestObject, 
    responsePicList, 
    onImageClick 
}) => {
    const skeletonBlock = (
        <Stack>
            <Skeleton height='20px' />
            <Skeleton height='20px' />
            <Skeleton height='20px' />
        </Stack>
    );

    const userPictureBlock = (pictureList) => (
        <Wrap justify='center' spacing='24px'> 
            {pictureList.map((pic) => (
                <Image
                    key={pic}
                    maxW={{ base: '100%', sm: '180px' }}
                    objectFit='cover'
                    src={pic}
                    loading='lazy'
                    alt='not found'
                    onClick={() => onImageClick(pic)}
                /> 
            ))}
        </Wrap>
    );

    return (
        <AccordionItem isDisabled={!requestObject.requestFulfilled}>
            <h2>
                <AccordionButton pl='0'>
                    <Box as="span" flex='1' textAlign='left'>
                        <Text fontSize='ml'>
                            Atsakymas
                        </Text>
                    </Box>
                    <AccordionIcon fontSize='24px' />
                </AccordionButton>
            </h2>
            <AccordionPanel pb={4}>
                <Text fontSize='ml' as='cite' whiteSpace='pre-line'>{requestObject.responseText}</Text>
                <Divider />
                <br />
                {requestObject.requestUuid in responsePicList ? 
                    userPictureBlock(responsePicList[requestObject.requestUuid]) : 
                    skeletonBlock}
            </AccordionPanel>
        </AccordionItem>
    );
};

const Editor = () => {
    const [answeredRequests, setAnsweredRequests] = useState([]);
    const [unansweredRequests, setUnansweredRequests] = useState([]);  
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [bioEntry, setBioEntry] = useState();
    const [isEditor, setIsEditor] = useState(false);
    const [charCount, setCharCount] = useState(0);
    const [picList, setPicList] = useState({});
    const [responsePicList, setResponsePicList] = useState({});
    const [activePic, setActivePic] = useState();
    const [usernameMap, setUsernameMap] = useState(new Map());
    const [showLoader, setShowLoader] = useState(false);
    // const [selectedImage, setSelectedImage] = useState([]);
    // const [presignedUrls, setPresignedUrls] = useState([]);
    const [requestUUID, setRequestUUID] = useState('abrakadabra');
    const FILE_SIZE = 500000; // 500KB
    const [requestUserUuid, setRequestUserUuid] = useState('');
    const [isAnsweredRequestsLoading, setIsAnsweredRequestsLoading] = useState(true);
    const [isUnansweredRequestsLoading, setIsUnansweredRequestsLoading] = useState(true);
    const [hasLoadedAnswered, setHasLoadedAnswered] = useState(false);
    // const [nextRefreshIn, setNextRefreshIn] = useState(10);
    const [timeSinceRefresh, setTimeSinceRefresh] = useState(0); // Initialize state for time since refresh


    const navigate = useNavigate();
    const toast = useToast()

    // const validateImage = (size) => {
    //   if (size > FILE_SIZE) {
    //     console.log('per didelis')
    //     // TODO: alertas neveikia ir padaryti downsizinga jei per didelis, o ne rasyti useriui errorus
    //     return { alert: "Max file size exceeded." };
    //   }
    // };

    // const recalculate = e => {
    //   console.log("event value:", e);
    //   setCharCount(e.target.value.length);
    // };
   
    const userPictureBlock = (pictureList) => {
        function activePicture(pictureLink){
            setActivePic(pictureLink);
            // console.log(pictureLink);
            onOpen();
        };
        return <Wrap justify='center' spacing='24px'> 
            {pictureList.map((pic) => 
                <Image
                    key={pic}
                    maxW={{ base: '100%', sm: '180px' }}
                    objectFit='cover'
                    src={pic}
                    loading='lazy'
                    alt='not found'
                    onClick={() => activePicture(pic)}
                /> 
            )}
            <Modal isOpen={isOpen} onClose={onClose} isCentered>
                <ModalOverlay  
                bg='blackAlpha.100' 
                />
                <ModalContent>
                    <ModalCloseButton />
                    <ModalBody>
                        <Center>
                            <Image
                                objectFit='cover'
                                src={activePic}
                                loading='lazy'
                                alt='not found'
                                onClick={onClose}
                            /> 
                        </Center>
                    </ModalBody>
                </ModalContent>
            </Modal>
        </Wrap>
    }

  //   const replySection = (requestObject) => <>
  //   {
  //   <AccordionItem isDisabled = {!requestObject.requestFulfilled}>
  //   <h2>
  //           <AccordionButton pl='0'>
  //             <Box as="span" flex='1' textAlign='left'>
  //             <Text fontSize='ml'>
  //               Atsakymas
  //             </Text>
  //             </Box>
  //             <AccordionIcon fontSize='24px' />
  //           </AccordionButton>
  //   </h2>
  //   <AccordionPanel pb={4}>
  //   <Text fontSize='ml' as='cite' whiteSpace='pre-line'>{requestObject.responseText}</Text>
  //   <Divider />
  //   <br></br>
  //     { requestObject.requestUuid in responsePicList ? userPictureBlock(responsePicList[requestObject.requestUuid]) : skeletonBlock}
  //   </AccordionPanel>
  // </AccordionItem>
  //   }
  //   </>

    const skeletonBlock = <>
    <Stack>
    <Skeleton height='20px' />
    <Skeleton height='20px' />
    <Skeleton height='20px' />
    </Stack>
    </>

    const skeletonas =  <>
    <Center>
    <Card maxWidth="80vw" minWidth='80vw' variant='outline'>
    <CardBody>
        <SkeletonText mt='4' noOfLines={1} spacing='4' skeletonHeight='4' width='15rem'/>
        <SkeletonText mt='4' noOfLines={1} spacing='4' skeletonHeight='2' width=' 7rem'/>
        <SkeletonText mt='4' noOfLines={8} spacing='4' skeletonHeight='2' />
        <Divider mb='4' mt='4'/>
        <SkeletonText mt='4' noOfLines={1} spacing='4' skeletonHeight='4' width='8rem'/>
    </CardBody>
    </Card>
    </Center>
  </>

function compareDates(a, b) {
  const comparison = Date.parse(new Date(a.createdAt)) - Date.parse(new Date(b.createdAt))
  return comparison * -1;
}

    useEffect(() => {
      const dataFetch = async () => {
        const data = await (
          await fetch("https://api.userfront.com/v0/self", {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: 'Bearer ' + Userfront.tokens.accessToken
            }
          })
        ).json();
      
        // console.log(data);
        // console.log(data?.authorization?.[TENANT]?.roles);
        if (data?.authorization?.[TENANT]?.roles.includes('editor')) 
          {setIsEditor(true)}
        else {
          navigate("/");
        } ;
      };
      
      dataFetch();
  }, []);   

  useEffect(() => {
    if (isEditor) {
      const fetchUnansweredRequests = async () => {
        setIsUnansweredRequestsLoading(true);
        try {
          const response = await fetch(
            'https://sckumwyv6l.execute-api.eu-central-1.amazonaws.com/requests-unanswered-editor',
            {
              method: 'GET',
              headers: {
                Authorization: 'Bearer ' + Userfront.tokens.accessToken,
                mode: "no-cors"
              }
            }
          );
          const data = await response.json();
          setUnansweredRequests(data.sort(compareDates));
          // setNextRefreshIn(10); // Reset countdown after fetch
        } catch (error) {
          console.error('Error fetching unanswered requests:', error);
        } finally {
          setIsUnansweredRequestsLoading(false);
        }
      };

      // Initial fetch
      fetchUnansweredRequests();

      const interval = setInterval(() => {
        setTimeSinceRefresh(prev => prev + 1); // Increment time every minute
      }, 60000); // 60000 ms = 1 minute

      return () => clearInterval(interval); // Cleanup on unmount

      // comment out auto refresh every 10min
      // // Set up refresh interval
      // const fetchInterval = setInterval(fetchUnansweredRequests, 600000);
      
      // // Set up countdown timer
      // const countdownInterval = setInterval(() => {
      //   setNextRefreshIn(prev => prev > 0 ? prev - 1 : 10);
      // }, 60000); // Update every minute

      // return () => {
      //   clearInterval(fetchInterval);
      //   clearInterval(countdownInterval);
      // };
    }
  }, [isEditor]);

  // Function to handle tab change
  const handleTabChange = async (index) => {
    if (index === 1 && !hasLoadedAnswered) {
      setIsAnsweredRequestsLoading(true);
      try {
        const response = await fetch(
          'https://sckumwyv6l.execute-api.eu-central-1.amazonaws.com/requests-answered-editor',
          {
            method: 'GET',
            headers: {
              Authorization: 'Bearer ' + Userfront.tokens.accessToken,
              mode: "no-cors"
            }
          }
        );
        const data = await response.json();
        setAnsweredRequests(data.sort(compareDates));
        setHasLoadedAnswered(true);
      } catch (error) {
        console.error('Error fetching answered requests:', error);
      } finally {
        setIsAnsweredRequestsLoading(false);
      }
    }
  };

  // padownloadinam useriu vardus ir fotkes kai jau yra padownloadintos uzklausos visos
  useEffect(() => {
    const requestFetch = async (userUuid) => {
      const data = await (
        await fetch(
          'https://sckumwyv6l.execute-api.eu-central-1.amazonaws.com/user-profile?userUuid=' + userUuid, 
          {
            method: 'GET',
            headers: {Authorization: 'Bearer ' + Userfront.tokens.accessToken, mode: "no-cors"}
          }
        )
      ).json();
      return data;
    };

    // Combine both arrays and get unique userUuids
    const allRequests = [...answeredRequests, ...unansweredRequests];
    const uniqueUserUuids = [...new Set(allRequests.map(request => request.userUuid))];

    // Fetch user data for any new userUuids
    uniqueUserUuids.forEach((userUuid) => {
      if (!usernameMap.has(userUuid)) {
        requestFetch(userUuid).then(response => {
          setUsernameMap(prevMap => new Map(prevMap).set(userUuid, response));
        });
      }
    });
  }, [answeredRequests, unansweredRequests]); // Update dependencies

  useEffect(() => {
    const picFetch = async () => {
      const data = await (
        await fetch(
          'https://sckumwyv6l.execute-api.eu-central-1.amazonaws.com/request-pics-editor', 
          {
            method: 'GET',
            headers: {Authorization: 'Bearer ' + Userfront.tokens.accessToken, mode: "no-cors"}
          }
        )
      ).json();
      // console.log('Request pics:', data);
      setPicList(data);
    };

    const responsePicFetch = async () => {
      const data = await (
        await fetch(
          'https://sckumwyv6l.execute-api.eu-central-1.amazonaws.com/response-pics-editor', 
          {
            method: 'GET',
            headers: {Authorization: 'Bearer ' + Userfront.tokens.accessToken, mode: "no-cors"}
          }
        )
      ).json();
      // console.log('Response pics:', data);
      setResponsePicList(data);
    };

    picFetch();
    responsePicFetch();
  }, [answeredRequests, unansweredRequests]); // Add these dependencies


  const bioFetch = async (userUuid) => {
    const data = await (
      await fetch(
        'https://sckumwyv6l.execute-api.eu-central-1.amazonaws.com/testas/user-bio?userUuid=' + userUuid, 
        {
          method: 'GET',
          headers: {Authorization: 'Bearer ' + Userfront.tokens.accessToken, mode: "no-cors"}
        }
      )
    ).json();
      // console.log(data);
      setBioEntry(data);
  };

const unanswered_requests = unansweredRequests?.map((request) =>
  !request.requestFulfilled ? 
  <>
   <Center>
   <Card maxWidth="80vw" minWidth='80vw' variant='outline' key={request.id}>
   <CardBody>
    <Grid templateColumns='repeat(5, 1fr)' gap={4}>
      <GridItem colSpan={4}>
      <Box>
       {request.payment_status === "paid" ? null: (<Badge colorScheme='red'>{request.payment_status}</Badge>)}
       {request.requestFulfilled ? (<Badge colorScheme='green' >Atsakyta</Badge>): (<Badge colorScheme='red'>Neatsakyta</Badge>)}
       <Heading size='md'>
       {request.requestTitle}
       </Heading>
       <Stat>
       <StatHelpText>Pateikta: {new Date(request.createdAt).toLocaleString("lt-LT")}</StatHelpText>
       {request.answeredAt? (<StatHelpText>Atsakyta: {new Date(request.answeredAt).toLocaleString("lt-LT")}</StatHelpText>) : null}
       </Stat>
       </Box>
      </GridItem>
      <GridItem colStart={5} colEnd={6}>
      <VStack>
        <Box
        display='flex'
        alignItems='right'
        justifyContent='flex-end'
        width='100%'
        height='10%'
        >
        <Popover>
          <PopoverTrigger>
           <Avatar _hover={{cursor: 'pointer'}} size='xl' name={usernameMap.get(request.userUuid)?.name} src={usernameMap.get(request.userUuid)?.image} referrerPolicy="no-referrer"  onClick={() => bioFetch(request.userUuid)}/> 
          </PopoverTrigger>
          <PopoverContent>
            <PopoverArrow />
            <PopoverCloseButton />
            <PopoverHeader>Bio</PopoverHeader>
            <PopoverBody>{bioEntry}</PopoverBody>
          </PopoverContent>
        </Popover>
      </Box>
      <Box
        display='flex'
        alignItems='right'
        justifyContent='flex-end'
        width='100%'
        height='10%'
        >
      <Text as='b' > {usernameMap.get(request.userUuid)?.name}</Text>
      </Box>
      </VStack>
      </GridItem>
    </Grid>

       <Box>
         <Text pt='2' fontSize='ml' whiteSpace='pre-line'>
           {request.requestText}
         </Text>
       </Box>
       <Accordion pt='4'  allowMultiple>
       <AccordionItem >
         {({ isExpanded }) => (
           <>
             <h2>
               <AccordionButton pl='0'>
                 <Box as="span" flex='1' textAlign='left'>
                 <Text fontSize='ml'>
                   Kliento nuotraukos
                 </Text>
                 </Box>
                 {isExpanded ? (
                   <AccordionIcon fontSize='24px' />
                 ) : (
                   <AccordionIcon fontSize='24px' />
                 )}
               </AccordionButton>
             </h2>
             <AccordionPanel pb={4}>
             { request.requestUuid in picList ? userPictureBlock(picList[request.requestUuid]) : skeletonBlock}
             </AccordionPanel>
           </>
         )}
       </AccordionItem>
       <AnswerForm 
           requestObject={request}
           showLoader={showLoader}
           setShowLoader={setShowLoader}
           setRequestUUID={setRequestUUID}
           setRequestUserUuid={setRequestUserUuid}
           hasLoadedAnswered={hasLoadedAnswered}
           setUnansweredRequests={setUnansweredRequests}
           setAnsweredRequests={setAnsweredRequests}
        />
     </Accordion>
   </CardBody>
   </Card>
   </Center>
 </> : null
);

const answered_requests = answeredRequests?.map((request) =>
  request.requestFulfilled ? 
  <>
   <Center>
   <Card maxWidth="80vw" minWidth='80vw' variant='outline' key={request.id}>
   <CardBody>
   <Grid templateColumns='repeat(5, 1fr)' gap={4}>
      <GridItem colSpan={4}>
      <Box>
      {/* Pirmas badge'as jeigu paid stadijoj tai nerodomas is viso, rodoma jei kazkas blogai */}
       {request.payment_status === "paid" ? null: (<Badge colorScheme='red'>{request.payment_status}</Badge>)}
       {request.requestFulfilled ? (<Badge colorScheme='blue' >Atsakyta</Badge>): (<Badge colorScheme='pink'>Neatsakyta</Badge>)}
       <Heading size='md'>
       {request.requestTitle}
       </Heading>
       <Stat>
       <StatHelpText>Pateikta:  {new Date(request.createdAt).toLocaleString("lt-LT")}</StatHelpText>
       {request.answeredAt? (<StatHelpText>Atsakyta: {new Date(request.answeredAt).toLocaleString("lt-LT")}</StatHelpText>) : null}
       </Stat>
       <Badge colorScheme='green'>{request.requestFulfilled}</Badge>
       </Box>
      </GridItem>
      <GridItem colStart={5} colEnd={6}>
      <VStack>
        <Box
        display='flex'
        alignItems='right'
        justifyContent='flex-end'
        width='100%'
        height='10%'
        >
        <Popover>
          <PopoverTrigger>
           <Avatar _hover={{cursor: 'pointer'}} size='xl' name={usernameMap.get(request.userUuid)?.name} src={usernameMap.get(request.userUuid)?.image} referrerPolicy="no-referrer" onClick={() => bioFetch(request.userUuid)}/> 
          </PopoverTrigger>
          <PopoverContent>
            <PopoverArrow />
            <PopoverCloseButton />
            <PopoverHeader>Bio</PopoverHeader>
            <PopoverBody>{bioEntry}</PopoverBody>
          </PopoverContent>
        </Popover>
      </Box>
      <Box
        display='flex'
        alignItems='right'
        justifyContent='flex-end'
        width='100%'
        height='10%'
        >
      <Text as='b' >{usernameMap.get(request.userUuid)?.name}</Text>
      </Box>
      </VStack>
      </GridItem>
    </Grid>

       <Box>
         <Text pt='2' fontSize='ml' whiteSpace='pre-line'>
           {request.requestText}
         </Text>
       </Box>
       <Accordion pt='4'  allowMultiple>
       <AccordionItem >
         {({ isExpanded }) => (
           <>
             <h2>
               <AccordionButton pl='0'>
                 <Box as="span" flex='1' textAlign='left'>
                 <Text fontSize='ml'>
                   Kliento nuotraukos
                 </Text>
                 </Box>
                 {isExpanded ? (
                   <AccordionIcon fontSize='24px' />
                 ) : (
                   <AccordionIcon fontSize='24px' />
                 )}
               </AccordionButton>
             </h2>
             <AccordionPanel pb={4}>
             { request.requestUuid in picList ? userPictureBlock(picList[request.requestUuid]) : skeletonBlock}
             </AccordionPanel>
           </>
         )}
       </AccordionItem>
       <ReplySection 
           requestObject={request}
           responsePicList={responsePicList}
           onImageClick={(pic) => {
               setActivePic(pic);
               onOpen();
           }}
       />
     </Accordion>
   </CardBody>
   </Card>
   </Center>
 </> : null
)

const all_requests = 
<>
  <Tabs isFitted variant='enclosed' size='lg' onChange={handleTabChange}>
    <TabList mb='1em'>
      <Tab bg="#fb8fff" color="white" _selected={{fontSize: '2em', border: '1px solid', mb:"-1px", color: 'white', bg: '#fb8fff' }}>
        Neatsakytos
      </Tab>
      <Tab 
      // bg="#0000ED"
      bg="#FFFFFF"
       color="black" _selected={{fontSize: '2em', border: '1px solid', mb:"-1px", color: 'black', bg: '#FFFFFF' }}>
        Atsakytos
      </Tab>
    </TabList>
    <TabPanels>
      <TabPanel>
        <Stack spacing='8'>
          {isUnansweredRequestsLoading ? (
            skeletonas
          ) : unansweredRequests?.length > 0 ? (
            unanswered_requests
          ) : (
            <Text>Nėra neatsakytų užklausų</Text>
          )}
        </Stack>
      </TabPanel>
      <TabPanel>
        <Stack spacing='8'>
          {isAnsweredRequestsLoading && !hasLoadedAnswered ? (
            skeletonas
          ) : answeredRequests?.length > 0 ? (
            answered_requests
          ) : (
            <Text>Nėra atsakytų užklausų</Text>
          )}
        </Stack>
      </TabPanel>
    </TabPanels>
  </Tabs>
</>

  if (isEditor) { 
  return (
    <>
    <Helmet>
      <title>Ką rengtis pagal Viliją Rušinskaitę - Atsakyti užklausas</title>
    </Helmet>
    <Box minH='2vw' />
    <Center>
    <Card border='0' maxWidth="80vw" minWidth='60vw' shadow='none'>
      <CardHeader>
        <Center>
          <VStack>
            <Heading as='h1' sx={{ fontSize: '32px', fontFamily: 'Montserrat, sans-serif' }}>Visos užklausos</Heading>
            <Text fontSize='sm' color='gray.500'>
              {/* Kitas atnaujinimas po {nextRefreshIn} min.  */}
              Praėjo {timeSinceRefresh} min. nuo paskutinio atnaujinimo
            </Text>
          </VStack>
        </Center>
      </CardHeader>
    </Card>
    </Center>
    <Box minH='2vw' />
    <Stack spacing='8'>
      {isEditor ? (
        isUnansweredRequestsLoading ? 
          skeletonas : 
          all_requests
      ) : null}
    </Stack>
    <Box minH='2vw'/>
    </>
  )
}

return null; // Return null if not editor
}

export default Editor;