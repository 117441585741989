import {Heading, Text, Box, Center, Card, CardHeader, CardBody, Icon, Stat, StatHelpText} from "@chakra-ui/react"
import { ChevronRightIcon } from '@chakra-ui/icons'; // Import an icon
import { Helmet } from 'react-helmet';
import {Link} from "react-router-dom";

export default function Blog() {
    return (
    <>
    <Helmet>
      <title>Ką rengtis pagal Viliją Rušinskaitę - Tinklaraštis</title>
      <meta name="description" content="Vilijos Rušinskaitės tinklaraštis" />
      <meta property="og:title" content="Tinklaraštis" />
      <meta property="og:description" content="Vilijos Rušinskaitės tinklaraštis" />
      <meta property="og:image" content="https://karengtis.lt/vilija1_small.jpg" />
      <meta property="og:url" content="https://www.karengtis.lt/blog" />
    </Helmet>
    <Box minH='2vw' />
    {/* <Center>
    <Card border='1px' maxWidth="80vw" minWidth='80vw' shadow='none'>
      <CardHeader >
        <Box minH='1vw' />
        <Center>
          <Heading as='h1' sx={{ fontSize: '32px', fontFamily: 'Montserrat, sans-serif' }}>Tinklaraštis</Heading>
        </Center>
        <Box minH='1vw' />
      </CardHeader>
    </Card>
    </Center>
    <Box minH='2vw' /> */}
    <Center>
    <Card border='1px' maxWidth="80vw" minWidth='60vw' shadow='none'>
      <CardHeader >
        <Box minH='1vw' />
        <Center>
          <Heading as='h1' sx={{ fontSize: '32px', fontFamily: 'Montserrat, sans-serif' }}>Spintos paruošimas pavasariui</Heading>
        </Center>
        <Box>
            <Stat>
                <StatHelpText mb='0' mt='4'>2025-03-30 </StatHelpText>
            </Stat>
        </Box>
      </CardHeader>
    <Center>
    <CardBody pt='0'>

        <Box>
          <Text pt='2' fontSize='xl'>
          Pavasaris jau čia (pagaliau!), o su juo kartu ir pokyčiai, reikalaujantys
emocinio, fizinio ir psichologinio prisitaikymo. Skamba kaip daug darbo, bet
tereikia leisti tam įvykti.
          </Text>
          <Text pt='2' fontSize='xl'>
Rituališkai atsinaujinimą kasmet pradedu nuo namų generalinio švarinimo ir
spintos paruošimo naujam lengvumo, išsilukštenimo ir suaktyvėjimo etapui.
Tik nebūtina spintos atšviežinimo daryti grandiozinių apimčių ir intensyvumo.
Nes ir pats pavasarėjimas Šiaurėje turi savo lėtą ir inertišką tempą. Tai nuo
ko pradėti?
          </Text>
          <Box minH='1vw' />
          <Link to="/tinklarastis/spintos_paruosimas_pavasariui"> {/* Adjust the path to your desired route */}
        <Text pt='2' fontSize='xl' color='#FB8FFF' cursor='pointer' textAlign='right'>
          Skaityti toliau <Icon as={ChevronRightIcon} />
        </Text>
        </Link>
        </Box>
    </CardBody>
    </Center>
    </Card>
    </Center>
    <Box minH='2vw' />
    <Center>
    <Card border='1px' maxWidth="80vw" minWidth='60vw' shadow='none'>
      <CardHeader >
        <Box minH='1vw' />
        <Center>
          <Heading as='h1' sx={{ fontSize: '32px', fontFamily: 'Montserrat, sans-serif' }}>Apie kelnių ilgį</Heading>
        </Center>
        <Box>
            <Stat>
                <StatHelpText mb='0' mt='4'>2025-03-23 </StatHelpText>
            </Stat>
        </Box>
      </CardHeader>
    <Center>
    <CardBody pt='0'>

        <Box>
          <Text pt='2' fontSize='xl'>
          Per savo ilgą klientų aprengimo karjerą parduotuvių matavimosi kabinose
esu dalyvavusi įvairiuose aptarimuose ir vertinimuose apie drabužio gulimą
ant kūno. Tuose pokalbiuose gimsta tiesa arba bent jau apsikeitimas savo
realybės vertinimu. Bet jeigu pokalbis pakrypsta panašia linkme kaip "visai
gražiai man čia ir per pečius, ir nugaroj. Spalva labai patinka, tik rankovės
per trumpos" - tai dažniausiai reiškia, kad buvo priimtas aiškus ir
neginčytinas sprendimas to drabužio dėvėtojui ir pirkėjui. Per trumpos
rankovės, nepriklausomai nuo pasikaustymo mados lauke, amžiaus ir
gyvenimo patirties yra universaliai nepageidaujama ir netoleruotina situacija.
Kaip koks prie batų prikibęs tualetinis popierius. Mane ši rankovių ilgio
vertinimo konstanta net savotiškai ramina pasaulyje, kuriame viskas nuolat
kinta milžinišku greičiu. Tik kodėl tai universaliai neatsispindi perkant kelnes?
Jų ilgis taip pat yra iškalbingas ir principingai pasirinktos ne per trumpos
kelnės visą įvaizdį gali kilstelti į sofistikuotą arba bent jau adekvatų,
bendražmogiškai kultūringą lygį. O mes juk kultūringi žmonės? Noriu jums
padėti priimti sau naudingesnius sprendimus kitą kartą perkant džinsus,
kelnes, ar kombinezoną. Visas reikalas yra tikrai nesudėtingas.
          </Text>
          <Box minH='1vw' />
          <Link to="/tinklarastis/apie_kelniu_ilgi"> {/* Adjust the path to your desired route */}
        <Text pt='2' fontSize='xl' color='#FB8FFF' cursor='pointer' textAlign='right'>
          Skaityti toliau <Icon as={ChevronRightIcon} />
        </Text>
        </Link>
        </Box>
    </CardBody>
    </Center>
    </Card>
    </Center>
    <Box minH='2vw' />
    </>
    )
  }