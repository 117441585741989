import {Heading, Text, Box, Center, Card, CardHeader, CardBody} from "@chakra-ui/react"
import BioBlock from "../dynamic/BioBlock";
import { Helmet } from 'react-helmet';

export default function Welcome() {
    return (
    <>
    <Helmet>
      <title>Ką rengtis pagal Viliją Rušinskaitę - Sveiki prisijungę!</title>
    </Helmet>
    <Box minH='2vw' />
    <Center>
    <Card border='1px' maxWidth="80vw" minWidth='60vw' shadow='none'>
      <CardHeader >
      <Box minH='1vw' />
        <Center>
          <Heading as='h1' sx={{ fontSize: '32px', fontFamily: 'Montserrat, sans-serif' }} >Sveiki prisijungę!</Heading>
        </Center>
      </CardHeader>
      <CardBody>
        <Box>
          <Text pt='2' fontSize='lg'>
            Kad galėčiau Jums suteikti personalizuotus atsakymus padėkite man save pažinti užpildydami šią formą
          </Text>
        </Box>
      </CardBody>
      <BioBlock/>
    </Card>
    </Center>
    </>
    )
  }