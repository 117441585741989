import {Heading, Text, Box, Center, Card, CardHeader, CardBody} from "@chakra-ui/react"
import { Helmet } from 'react-helmet';

export default function HowItWorks() {
    return (
    <>
    <Helmet>
      <title>Ką rengtis pagal Viliją Rušinskaitę - Kaip tai veikia?</title>
    </Helmet>
    <Box minH='2vw' />
    <Center>
    <Card border='1px' maxWidth="80vw" minWidth='60vw' shadow='none'>
      <CardHeader >
        <Box minH='1vw' />
        <Center>
          <Heading as='h1' sx={{ fontSize: '32px', fontFamily: 'Montserrat, sans-serif' }}>Kaip tai veikia?</Heading>
        </Center>
      </CardHeader>
    <Center>
    <CardBody pt='0'>
        <Box>
      <Text pt='0' fontSize='xl' >
      Parašykite kuo tiksliau jūsų dabartinį įvaizdžio poreikį nusakančią užklausą: kokiu tikslu rengsitės,
ko trūksta jūsų deriniui ar dabartinei stiliaus krypčiai iki pilnos laimės. Jeigu nežinote nuo ko
pradėti ir pavyzdžiui, eisite į vestuves, būtų informatyvu, jeigu parašytumėte renginio laiką (metų
laikas diktuoja pasirinkimus), lokaciją, ar tai didelė šventė svečių kiekio prasme, ar ji turi iš anksto
numatytą vizualią tematiką. Būtinai aprašykite savo visus Už ir Prieš, pavyzdžiui, ar jūs avite
aukštakulnius, ar vilkite mini sijonus, ar mėgstat ryškias spalvas, o gal niekada nesirengiate juodai
ir mėgstat tik natūralų ir minimalistinį makiažą. Kuo tiksliau ir konkrečiau įvardinsite savo poreikius
ir norus užklausoje, tuo tiksliau aš galėsiu sukurti ir pasiūlyti jums tinkančią įvaizdžio idėją. 
        </Text>
        <Text pt='2' fontSize='xl'>
        Taip pat prisekite iki 10 nuotraukų, kurios man padėtų geriau suprasti jūsų poreikį ir kaip jį atliepti.
Visada naudokite kuo naujesnes nuotraukas: bent vieną visu ūgiu, kurioje aiškiai matosi jūsų
sudėjimas ir kūno linijos, proporcijos ir panašiai. Portretą, kuriame matosi jūsų akių, plaukų spalva,
oda ir bendrai jūsų bruožai. Tokios nuotraukas padeda prireikus pasiūlyti ir įvaizdį papildančią
makiažo, kirpimo ar šukuosenos idėją. Dar prisekite keletą nuotraukų, kuriose gerai matosi, kaip
jūs įprastai rengiatės arba derinio išbaigimo atveju parodykite visus jau turimus įvaizdžio
elementus (suknelę, batelius, makiažo idėją ir panašiai). Ir bendrai dar prisekite nuotraukų savo
nuožiūra - ką būtinai turėčiau suprasti ir žinoti apie jūsų stiliaus poreikį prieš rašydama jums
atsakymą.
        </Text>
        <Text pt='2' fontSize='xl'>
        Į visas jūsų užklausas atsakau asmeniškai pati, todėl rašydami galite būti tikri - bendraujate
tiesiogiai su manimi, Vilija Rušinskaite.
        </Text>
        <Text pt='2' fontSize='xl'>
        Užklausas atsakysiu per 2 darbo dienas. 
        </Text>
        <Text pt='2' fontSize='xl'>
        Norėjau sukurti tokią asmeninio stiliaus konsultacijų terpę, kurioje man užtektų laiko įsigilinti į
klausimus ir gerai apgalvoti atsakymus. Kurioje nebūtų automatiškai siuntinėjami iš anksto
paruošti šabloniniai, visiems ir tuo pačiu niekam tinkantys atsakymai ir patarimai. Todėl kol
atsakinėju asmeniškai ir viena - trumpųjų, super greitų užklausų pasirinkimo nebus. O bus tik
dėmesingas ir apgalvotas konsultavimas labai žmogišku tempu. 
        </Text>
        <Text pt='2' fontSize='xl'>
        Taip pat prie atsakymų kol kas nebus segamos drabužių ir aksesuarų įsigijimo nuorodos.
Atsakymai yra rekomendacinio pobūdžio, nukreipiantys jus reikiama linkme su vizualiniais
pavyzdžiais ir praktiniais patarimais. 
        </Text>
        <Text pt='2' fontSize='xl'>
        Su kiekvienu klientu bendrauju remdamasi visiško priėmimo ir nulinio smerkimo dėl gyvenimo
pasirinkimų ar išvaizdos principu. Tad tikiuosi, kad su manimi bendrausite be įtampos ir baimės
kažkuo neįtikti. Visi esame vienodai verti stiliaus patarimų ir pozityvaus paskatinimo būti savimi.
Tikiuosi, kad su laiku &quot;ką rengtis&quot; taps palaikančia ir draugiška bendruomene. 
        </Text>
          <Box minH='1vw' />
        </Box>
    </CardBody>
    </Center>
    </Card>
    </Center>
    <Box minH='2vw' />
    </>
    )
  }