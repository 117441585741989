import React from "react";
import {useState, useEffect} from 'react';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import Userfront from "@userfront/toolkit/react";
import { Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    Textarea,
    // Input,
    ModalCloseButton,
    ModalBody,
    FormControl,
    FormLabel,
    FormErrorMessage,
    useToast,
    Button } from "@chakra-ui/react"


const BioModal = (props) => {

  const initialRef = React.useRef(null)
  const finalRef = React.useRef(null)

  const [bioEntry, setBioEntry] = useState();
  const [isBioLoading, setIsBioLoading] = useState(true);

  const toast = useToast();

  useEffect(() => {
    const dataFetch = async () => {
      const data = await (
        await fetch(
          'https://sckumwyv6l.execute-api.eu-central-1.amazonaws.com/testas/user-bio?userUuid=' + Userfront.user.userUuid, 
          {
            method: 'GET',
            headers: {Authorization: 'Bearer ' + Userfront.tokens.accessToken, mode: "no-cors"}
          }
        )
      ).json();

      setBioEntry(data);
      setIsBioLoading(false);
    };

    dataFetch();
}, []);  

  const formik = useFormik({
    initialValues: {
      bio: ''
    },
    validationSchema: Yup.object({
      bio: Yup.string()
        .max(1000, 'Ne daugiau kaip 1000 simbolių!')
        // .required('Trūksta informacijos apie Jus'),
    }),
    onSubmit: values => {
      // console.log(values);
        fetch('https://sckumwyv6l.execute-api.eu-central-1.amazonaws.com/testas/user-bio', {
          headers: {Authorization: 'Bearer ' + Userfront.tokens.accessToken, 
          "Content-Type": "application/json"},
          method: 'POST',
          body: JSON.stringify({
            "userUuid": Userfront.user.userUuid,
            "bio": values.bio})
        })
        .then(
          success => {
            setBioEntry(values.bio);
            // console.log(success);
            props.onModalClose();
            toast({
              title: 'Ačiū',
              description: "Informacija apie Jus išsaugota",
              status: 'success',
              duration: 3000,
              isClosable: true,
            })
           }
        )
        .catch(
          error => console.dirxml(error.message) // Handle the error response object
        );        
    }});  

  return (
    <Modal
        initialFocusRef={initialRef}
        finalFocusRef={finalRef}
        isOpen={props.isOpen}
        onClose={props.onModalClose}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Apie mane</ModalHeader>
          <ModalCloseButton />
          <ModalBody pb={6}>
            <FormControl isInvalid={formik.touched.bio && formik.errors.bio}>
              <FormLabel>Trumpai apie Jus</FormLabel>
              <Textarea
                id="bio"
                placeholder={bioEntry}
                _placeholder={{ opacity: 1, color: 'black' }}
                {...formik.getFieldProps('bio')}
              />
              {/* <Input 
              id="bio"
              type="text"
              // ref={initialRef} 
              placeholder={bioEntry}
              _placeholder={{ opacity: 1, color: 'black' }}
              {...formik.getFieldProps('bio')
            }
              /> */}
              <FormErrorMessage>{formik.errors.bio}</FormErrorMessage>
            </FormControl>
          </ModalBody>

          <ModalFooter>
            <Button 
            colorScheme='white' 
            color='black.500'
            borderColor='black.500'
            borderWidth='2px'
            variant='outline'
            sx={{
              '&:hover': {
                bg: "rgba(251, 143, 255, 0.4)"
              },
            }}
             mr={3} onClick={formik.handleSubmit}>
              Išsaugoti
            </Button>
            <Button 
                  colorScheme='white' 
                  color='black.500'
                  borderColor='black.500'
                  borderWidth='2px'
                  variant='outline'
                  sx={{
                    bg: "#E9E9E9",
                  }}
            onClick={props.onModalClose}
            >Atšaukti</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
  )

};

export default BioModal;