import {Heading, Text, Box, Wrap, Modal, ModalOverlay, ModalContent, ModalCloseButton, ModalBody, useDisclosure, Image, Center, Card, CardHeader, CardBody, Stack, Stat, Skeleton, SkeletonText, StatHelpText, Divider, AccordionIcon, AccordionButton, AccordionItem, Accordion, AccordionPanel} from "@chakra-ui/react";
import React from "react";
import {useState, useEffect} from 'react';
import Userfront from "@userfront/toolkit/react";
import { Helmet } from 'react-helmet';


// TODO: move to dynamic component folder
export default function MyRequests() {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [requestList, setRequestList] = useState([]);
  const [isRequestListLoading, setIsRequestListLoading] = useState(true);
  const [picList, setPicList] = useState({});
  const [responsePicList, setResponsePicList] = useState({});
  const [activePic, setActivePic] = useState();

  const skeletonas =  <>
  <Center>
  <Card maxWidth="80vw" minWidth='80vw' variant='outline'>
  <CardBody>
      <SkeletonText mt='4' noOfLines={1} spacing='4' skeletonHeight='4' width='15rem'/>
      <SkeletonText mt='4' noOfLines={1} spacing='4' skeletonHeight='2' width=' 7rem'/>
      <SkeletonText mt='4' noOfLines={8} spacing='4' skeletonHeight='2' />
      <Divider mb='4' mt='4'/>
      <SkeletonText mt='4' noOfLines={1} spacing='4' skeletonHeight='4' width='8rem'/>
  </CardBody>
  </Card>
  </Center>
</>

  const replySection = (requestObject) => <>
  {(!requestObject.requestFulfilled? 
  <AccordionItem isDisabled = {!requestObject.requestFulfilled}>
        <h2>
                <AccordionButton pl='0'>
                  <Box as="span" flex='1' textAlign='left'>
                  <Text fontSize='ml'>
                    ATSAKYMAS RUOŠIAMAS
                  </Text>
                  </Box>
                  <AccordionIcon fontSize='24px' />
                </AccordionButton>
        </h2>
        <AccordionPanel pb={4}>
          ATSAKYMO TEKSTAS
        </AccordionPanel>
  </AccordionItem>
  : 
  <AccordionItem isDisabled = {!requestObject.requestFulfilled}>
  <h2>
          <AccordionButton pl='0'>
            <Box as="span" flex='1' textAlign='left'>
            <Text fontSize='ml'>
              Atsakymas
            </Text>
            </Box>
            <AccordionIcon fontSize='24px' />
          </AccordionButton>
  </h2>
  <AccordionPanel pb={4}>
  <Text fontSize='ml' as='cite' whiteSpace='pre-line'>{requestObject.responseText}</Text>
  <Divider />
  <br></br>
    { requestObject.requestUuid in responsePicList ? userPictureBlock(responsePicList[requestObject.requestUuid]) : skeletonBlock}
  </AccordionPanel>
</AccordionItem>
  ) }
  </>

const userPictureBlock = (pictureList) => {
   function activePicture(pictureLink){
    setActivePic(pictureLink);
    // console.log(pictureLink);
    onOpen();
   };
   return <Wrap justify='center' spacing='24px'> 
   {pictureList.map((pic) => 
              <Image
                key = {pic}
                maxW={{ base: '100%', sm: '180px' }}
                objectFit='cover'
                src={pic}
                loading='lazy'
                alt='not found'
                onClick={() => activePicture(pic)}
              /> 
              )}
              <Modal isOpen={isOpen} onClose={onClose} isCentered>
                <ModalOverlay  
                bg='blackAlpha.100' 
                />
                <ModalContent>
                  <ModalCloseButton />
                  <ModalBody>
                    <Center>
                    <Image
                  objectFit='cover'
                  src={activePic}
                  loading='lazy'
                  alt='not found'
                  onClick={onClose}
                /> 
                </Center>
                  </ModalBody>
                  </ModalContent>
                </Modal>
              </Wrap>}

  const skeletonBlock = <>
  <Stack>
  <Skeleton height='20px' />
  <Skeleton height='20px' />
  <Skeleton height='20px' />
  </Stack>
  </>

const requests = requestList?.map((request, index) =>
   <>
    <Center>
    <Card maxWidth="80vw" minWidth='80vw' variant='outline' key = {requestList[index].id}>
    <CardBody>
        <Heading size='md'>
        {requestList[index].requestTitle}
        </Heading>
        <Stat>
        <StatHelpText>{new Date(requestList[index].createdAt).toLocaleString("lt-LT")}</StatHelpText>
        </Stat>
        <Box>
          <Text pt='2' fontSize='ml' whiteSpace='pre-line'>
            {requestList[index].requestText}
          </Text>
        </Box>
        <Accordion pt='4'  allowMultiple>
        <AccordionItem >
          {({ isExpanded }) => (
            <>
              <h2>
                <AccordionButton pl='0'>
                  <Box as="span" flex='1' textAlign='left'>
                  <Text fontSize='ml'>
                    Mano nuotraukos
                  </Text>
                  </Box>
                  {isExpanded ? (
                    <AccordionIcon fontSize='24px' />
                  ) : (
                    <AccordionIcon fontSize='24px' />
                  )}
                </AccordionButton>
              </h2>
              <AccordionPanel pb={4}>
              { requestList[index].requestUuid in picList ? userPictureBlock(picList[requestList[index].requestUuid]) : skeletonBlock}
              </AccordionPanel>
            </>
          )}
        </AccordionItem>
        {replySection(requestList[index])}
      </Accordion>
    </CardBody>
    </Card>
    </Center>
  </>
)

function compareDates(a, b) {
   const comparison = Date.parse(new Date(a.createdAt)) - Date.parse(new Date(b.createdAt))
   return comparison * -1;
}

  useEffect(() => {
    const dataFetch = async () => {
      const data = await (
        await fetch(
          'https://sckumwyv6l.execute-api.eu-central-1.amazonaws.com/requests?userUuid=' + Userfront.user.userUuid, 
          {
            method: 'GET',
            headers: {Authorization: 'Bearer ' + Userfront.tokens.accessToken, mode: "no-cors"}
          }
        )
      ).json();
      const filteredData = data.filter(request => request.payment_status === "paid"); // Filter requests
      setRequestList(filteredData.sort(compareDates)); // Set filtered and sorted request list
      // console.log(filteredData); // Log filtered data
      setIsRequestListLoading(false);
    };

    dataFetch();
}, []);   


useEffect(() => {
  const picFetch = async () => {
    const data = await (
      await fetch(
        'https://sckumwyv6l.execute-api.eu-central-1.amazonaws.com/request-pics', 
        {
          method: 'GET',
          headers: {Authorization: 'Bearer ' + Userfront.tokens.accessToken, mode: "no-cors"}
        }
      )
    ).json();
    // console.log(data);
    setPicList(data);
  };
  const responsePicFetch = async () => {
    const data = await (
      await fetch(
        'https://sckumwyv6l.execute-api.eu-central-1.amazonaws.com/response-pics', 
        {
          method: 'GET',
          headers: {Authorization: 'Bearer ' + Userfront.tokens.accessToken, mode: "no-cors"}
        }
      )
    ).json();
    // console.log(data);
    setResponsePicList(data);
  };

  picFetch();
  responsePicFetch();
}, [setIsRequestListLoading]);   


    return (
    <>
    <Helmet>
      <title>Ką rengtis pagal Viliją Rušinskaitę - Mano užklausos</title>
    </Helmet>
    <Box minH='2vw' />
    <Center>
    <Card border='1px' maxWidth="80vw" minWidth='60vw' shadow='none'>
      <CardHeader >
      <Box minH='1vw' />
        <Center>
          <Heading as='h1' sx={{ fontSize: '32px', fontFamily: 'Montserrat, sans-serif', textAlign: 'center' }} >Mano užklausos</Heading>
        </Center>
        <Box minH='1vw' />
      </CardHeader>
    </Card>
    </Center>
    <Box minH='2vw' />
    <Center>
    </Center>
    <Stack spacing='8'>
    {isRequestListLoading? skeletonas : requests}
    </Stack>
    <Box minH='2vw'/>
    </>
    )
  }