import React from "react";
import {useState, useEffect} from 'react';
import Userfront from "@userfront/toolkit/react";
import { useFormik } from 'formik';
import * as Yup from 'yup';
import {useNavigate} from "react-router-dom";
import { Card, CardHeader, CardBody, Box, Heading, useToast, Center, Button, Textarea, FormErrorMessage, FormControl } from '@chakra-ui/react'

const BioBlock = (status) => {
    const [bioEntry, setBioEntry] = useState();
    const [isBioLoading, setIsBioLoading] = useState(true);

    const navigate = useNavigate();
    const toast = useToast()

    const formik = useFormik({
      initialValues: {
        bio: ''
      },
      validationSchema: Yup.object({
        bio: Yup.string()
          .max(1000, 'Ne daugiau kaip 1000 simbolių!')
          .required('Trūksta informacijos apie Jus'),
      }),
      onSubmit: values => {
        // console.log(values);
          fetch('https://sckumwyv6l.execute-api.eu-central-1.amazonaws.com/testas/user-bio', {
            headers: {Authorization: 'Bearer ' + Userfront.tokens.accessToken, 
            "Content-Type": "application/json"},
            method: 'POST',
            body: JSON.stringify({
              "userUuid": Userfront.user.userUuid,
              "bio": values.bio})
          })
          .then(
            success => {
              setBioEntry(values.bio);
              // console.log(success);
              toast({
                title: 'Ačiū',
                description: "Informacija apie Jus išsaugota",
                status: 'success',
                duration: 3000,
                isClosable: true,
              })
              navigate("/");
             }
          )
          .catch(
            error => console.dirxml(error.message) // Handle the error response object
          );        
      }});

    useEffect(() => {
        const dataFetch = async () => {
          const data = await (
            await fetch(
              'https://sckumwyv6l.execute-api.eu-central-1.amazonaws.com/testas/user-bio?userUuid=' + Userfront.user.userUuid, 
              {
                method: 'GET',
                headers: {Authorization: 'Bearer ' + Userfront.tokens.accessToken, mode: "no-cors"}
              }
            )
          ).json();
    
          setBioEntry(data);
          setIsBioLoading(false);
        };
    
        dataFetch();
    }, []);   

    if (!isBioLoading) {
      if (bioEntry?.length > 0 ) {
        navigate("/");
      } else {
      return       <div>
      <Box minH='2vw' />
      <Center>
      <Card border='0px' maxWidth="80vw" minWidth='60vw' boxShadow={0}>
      <CardHeader >
          <Center>
          <Heading size='md'>Trumpai apie Jus</Heading>
          </Center>
      </CardHeader>
      <CardBody>
          <Box>
          <form onSubmit={formik.handleSubmit}>
       <FormControl isInvalid={formik.touched.bio && formik.errors.bio} >
      <Textarea
         id="bio"
         placeholder="Pavyzdžiui: ūgis, amžius, veikla, pomėgiai ir pan."
         {...formik.getFieldProps('bio')}
       />
       <FormErrorMessage>{formik.errors.bio}</FormErrorMessage>
       </FormControl>
      <br></br>
      <Box minH='1vw' />
       <br/>
       <Button type="submit"  
      colorScheme='white' 
      color='black.500'
      borderColor='black.500'
      borderWidth='2px'
      variant='outline'
      sx={{
        '&:hover': {
          bg: "rgba(251, 143, 255, 0.4)"
        },
      }}
      width="full">Išaugoti</Button>
     </form>
      </Box>
      </CardBody>
      </Card>
      </Center>
      <Box minH='2vw' />
    </div>
      }
    } else {
      return null
    }

  };

export default BioBlock;