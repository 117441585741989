import {Heading, Box, Center, Card, CardHeader, Accordion, Text,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon} from "@chakra-ui/react"
import { Helmet } from 'react-helmet';

export default function FAQ() {
    return (
    <>
    <Helmet>
      <title>Ką rengtis pagal Viliją Rušinskaitę - D.U.K.</title>
      <meta name="description" content="Dažniausiai užduodami klausimai apie Ką Rengtis pagal Viliją Rušinskaitę platformą" />
      <meta property="og:title" content="Dažniausiai užduodami klausimai" />
      <meta property="og:description" content="Dažniausiai užduodami klausimai apie Ką Rengtis pagal Viliją Rušinskaitę platformą" />
      <meta property="og:image" content="https://karengtis.lt/vilija1_small.jpg" />
      <meta property="og:url" content="https://www.karengtis.lt/duk" />
    </Helmet>
    <Box minH='2vw' />
    <Center>
    <Card border='1px' maxWidth="80vw" minWidth='80vw' shadow='none'>
      <CardHeader >
      <Box minH='1vw' />
        <Center>
          <Heading as='h1' sx={{ fontSize: '32px', fontFamily: 'Montserrat, sans-serif' }} >D.U.K.</Heading>
        </Center>
      </CardHeader>
      <Box minH='2vw' />
      <Accordion 
      // defaultIndex={[0]} 
      allowMultiple>
    <AccordionItem>
      <h2>
        <AccordionButton>
          <Box as="span" flex='1' textAlign='left' fontWeight='bold'>
          Kiek kainuoja viena užklausa?
          </Box>
          <AccordionIcon />
        </AccordionButton>
      </h2>
      <AccordionPanel pb={4}><Text fontSize='lg'>
      35€
      </Text>
      </AccordionPanel>
    </AccordionItem>
    <AccordionItem>
      <h2>
        <AccordionButton>
          <Box as="span" flex='1' textAlign='left' fontWeight='bold'>
          Kaip rašyti užklausą?
          </Box>
          <AccordionIcon />
        </AccordionButton>
      </h2>
      <AccordionPanel pb={4}><Text fontSize='lg'>
      Suformuokite vieną, labai konkretų klausimą apie savo asmeninį stilių ir po juo prisekite kuo
daugiau savo nuotraukų, kad man būtų lengviau formuluoti savo atsakymą ir duoti tikslingus
patarimus jums.
      </Text>
      </AccordionPanel>
    </AccordionItem>
    <AccordionItem>
      <h2>
        <AccordionButton>
          <Box as="span" flex='1' textAlign='left' fontWeight='bold'>
          Į kokius klausimus galiu gauti atsakymą?
          </Box>
          <AccordionIcon />
        </AccordionButton>
      </h2>
      <AccordionPanel pb={4}>
      <Text fontSize='lg'>
      Į bet kokius klausimus, susijusius su jūsų kasdieniu ir proginiu asmeniniu stiliumi - nuo
šukuosenos, makiažo ar manikiūro, pėdkelnių tankumo ir atspalvio, tinkamiausio kelnių ar džinsų
silueto, iki vestuvinio nuometo ilgio ir bendros vestuvinio įvaizdžio dermės net ir su puokšte,
lagamino pakavimo patarimų komandiruotei, sezoninio spintos atnaujinimo, tvaresnių stiliaus
pasirinkimų idėjų ir asmeniškumo suteikimo savo įvaizdžio pasirinkimams. Taip pat į klausimus
drabužių, batų, aksesuarų ar rankinių pirkimo ir pasirinkimo dilemomis. Mielai suderinsiu net jūsų
gyvūnėlių asmeninio stiliaus akcentus. Taip pat laukiu ir filosofinių ar abstraktesnių jūsų klausimų
apie madą, dizainerių kūrybą, artimų savo vertybėmis prekinių ženklų pasirinkimą ar stilisto darbo
specifiką.<br/>
Tačiau klausimų drabužių ar darbinių uniformų dizaino, gamybos temomis, interjero elementų
parinkimo, kosmetikos ar grožio procedūrų temomis nekonsultuoju.
      </Text>
      </AccordionPanel>
    </AccordionItem>
    <AccordionItem>
      <h2>
        <AccordionButton>
          <Box as="span" flex='1' textAlign='left' fontWeight='bold'>
          Ar atsakote ir į vyrų asmeninio stiliaus užklausas?
          </Box>
          <AccordionIcon />
        </AccordionButton>
      </h2>
      <AccordionPanel pb={4}>
      <Text fontSize='lg'>
      Tikrai taip.
      </Text>
      </AccordionPanel>
    </AccordionItem>
    <AccordionItem>
      <h2>
        <AccordionButton>
          <Box as="span" flex='1' textAlign='left' fontWeight='bold'>
          Ar galima užklausą siųsti kaip porai, pvz., kartu einant į šventę ir norint susiderinti
          įvaizdžius?
          </Box>
          <AccordionIcon />
        </AccordionButton>
      </h2>
      <AccordionPanel pb={4}>
      <Text fontSize='lg'>
      Užklausos turi būti asmeninės. Jeigu užklausoje yra daugiau negu vienas klausimas ar daugiau
negu vienas klausiantysis - atsakysiu į pirmą pateiktą klausimą apie pirmą klausiantį klientą. Todėl
poros įvaizdžius liečiančias užklausas siųskite atskirai, bet tokiu atveju pridėkite ir savo antros
pusės įvaizdžio nuotrauką.
      </Text>
      </AccordionPanel>
    </AccordionItem>
    <AccordionItem>
      <h2>
        <AccordionButton>
          <Box as="span" flex='1' textAlign='left' fontWeight='bold'>
          Kokias nuotraukas prisegti užklausoje?
          </Box>
          <AccordionIcon />
        </AccordionButton>
      </h2>
      <AccordionPanel pb={4}>
      <Text fontSize='lg'>
      Tokias, kuriose jūsų veido bruožai, plaukų spalva ir kūno proporcijos matosi gana aiškiai. Idealu -
jūsų asmeninės, be kitų žmonių nuotraukos (draugų, antrų pusių, vaikų ir panašiai). Konsultacijai
apie jūsų asmeninio stiliaus atnaujinimąnir tolimesnį vystymą tikslingos yra jūsų asmeninio stiliaus
įprastus pasirinkimus atspindinčių skirtingų įvaizdžių nuotraukos rinkinys.
      </Text>
      </AccordionPanel>
    </AccordionItem>
    <AccordionItem>
      <h2>
        <AccordionButton>
          <Box as="span" flex='1' textAlign='left' fontWeight='bold'>
          Kaip žinoti, kad mano atsiųstos nuotraukos nebus panaudotos niekur kitur ir ar jų
          nepažiūrės tretieji asmenys?
          </Box>
          <AccordionIcon />
        </AccordionButton>
      </h2>
      <AccordionPanel pb={4}>
      <Text fontSize='lg'>
      Nuotraukos naudojamos tik paslaugų suteikimo tikslais. Taip pat šioje internetinėje svetainėje yra
įgyvendinamos elektroninės apsaugos priemonės, draudžiančios vienam vartotojui matyti kito
vartotojo duomenis. Daugiau informacijos apie tai galite rasti "Privatumo politikos" skiltyje.
      </Text>
      </AccordionPanel>
    </AccordionItem>
    <AccordionItem>
      <h2>
        <AccordionButton>
          <Box as="span" flex='1' textAlign='left' fontWeight='bold'>
          Kada gausiu stilistės atsakymą į užklausą?
          </Box>
          <AccordionIcon />
        </AccordionButton>
      </h2>
      <AccordionPanel pb={4}>
      <Text fontSize='lg'>
      Per dvi darbo dienas nuo jūsų užklausos išsiuntimo. Jeigu užklausą siuntėte savaitgalį, švenčių
      arba valstybinių nedarbo dienų metu - atsakymą gausite per artimiausias dvi darbo dienas.
      </Text>
      </AccordionPanel>
    </AccordionItem>
    <AccordionItem>
      <h2>
        <AccordionButton>
          <Box as="span" flex='1' textAlign='left' fontWeight='bold'>
          Kodėl atsakymo reikia laukti dvi darbo dienas?
          </Box>
          <AccordionIcon />
        </AccordionButton>
      </h2>
      <AccordionPanel pb={4}>
      <Text fontSize='lg'>
      Todėl, kad norėdama apgalvotai ir kokybiškai atsakyti į kiekvieną jūsų atsiųstą užklausą pasirinkau
pasilikti pakankamai laiko tai daryti dedikuotai. Taip pat dėl to, kad į kiekvieną užklausą atsakau
viena pati - platformoje nedirba jokie papildomi stilistai. Čia bendraujate visada tiesiogiai tik su
manimi.
      </Text>
      </AccordionPanel>
    </AccordionItem>
    <AccordionItem>
      <h2>
        <AccordionButton>
          <Box as="span" flex='1' textAlign='left' fontWeight='bold'>
          Kaip sužinoti, kad mano užklausa jau yra atsakyta?
          </Box>
          <AccordionIcon />
        </AccordionButton>
      </h2>
      <AccordionPanel pb={4}>
      <Text fontSize='lg'>
      Jūsų nurodytu elektroninio pašto adresu bus atsiunčiamas automatinis pranešimas apie parengtą
atsakymą, kurį galėsite perskaityti prisijungę prie savo asmeninės paskyros "Ką rengtis"
platformoje, "Mano užklausos" skyrelyje.
      </Text>
      </AccordionPanel>
    </AccordionItem>
    <AccordionItem>
      <h2>
        <AccordionButton>
          <Box as="span" flex='1' textAlign='left' fontWeight='bold'>
          Ką daryti, jeigu stilistės atsakymas man nepatinka?
          </Box>
          <AccordionIcon />
        </AccordionButton>
      </h2>
      <AccordionPanel pb={4}>
      <Text fontSize='lg'>
      Kad to nenutiktų, savo rašomoje užklausoje stenkitės kuo tikslingiau formuluoti klausimą apie
savo stiliaus dilemą ir parinkite kuo informatyvesnes, šviesesnes ir aiškesnes savo nuotraukas. Jų
visada prisekite daugiau, kad neįvyktų jūsų poreikio "nenuskaitymas". Kuo aiškiau nupasakosite
savo poreikį ar problemą - tuo man bus lengviau atsakyti taip, kad iš tikrųjų galėčiau jums padėti.
      </Text>
      </AccordionPanel>
    </AccordionItem>
    <AccordionItem>
      <h2>
        <AccordionButton>
          <Box as="span" flex='1' textAlign='left' fontWeight='bold'>
          Kokiais būdais galiu sumokėti už užklausą?
          </Box>
          <AccordionIcon />
        </AccordionButton>
      </h2>
      <AccordionPanel pb={4}>
      <Text fontSize='lg'>
      Bankine kortele.
      </Text>
      </AccordionPanel>
    </AccordionItem>
    <AccordionItem>
      <h2>
        <AccordionButton>
          <Box as="span" flex='1' textAlign='left' fontWeight='bold'>
          Ar galiu tikėtis užklausos atsakyme pirkimo nuorodų su konkrečiais, man
          rekomenduojamais daiktais?
          </Box>
          <AccordionIcon />
        </AccordionButton>
      </h2>
      <AccordionPanel pb={4}>
      <Text fontSize='lg'>
      Ne, daiktų pirkimo nuorodos kol kas nepateikinėjamos, užklausos atsakomos patarimo principu.
      </Text>
      </AccordionPanel>
    </AccordionItem>
    <AccordionItem>
      <h2>
        <AccordionButton>
          <Box as="span" flex='1' textAlign='left' fontWeight='bold'>
          Ar įmanoma šiomis užklausomis susitvarkyti visą savo spintą?
          </Box>
          <AccordionIcon />
        </AccordionButton>
      </h2>
      <AccordionPanel pb={4}>
      <Text fontSize='lg'>
      Ir taip, ir ne.<br/>
Taip, jeigu jūs jau pasidarėt savo spintos reviziją ir joje nebėra jokių nebedėvimų ir jums šiuo metu
nebeaktualių drabužių ir aksesuarų kritimo, būklės, spalvų, audinių ir silueto prasme. Visada galiu
pasiūlyti sprendimų, kokia kryptimi judėti toliau, kokio tipo drabužių ir aksesuarų jums dabar
pravestų įsigyti. Taip pat galiu sudaryti jums reikalingų pirkinių sąrašą, jeigu parodysite aiškiai, ką
šiuo metu turite ir nupasakosite, kokia stilistika jums artima širdžiai. Naudokite kuo specifiškesnius
žodžius tam apibūdinti, nes "gražu", "madinga" ar "stilinga" yra per daug abstraktūs ir per plačiai
interpretuotini apibūdinimai.<br/>
Ne, jeigu jūsų spinta pilna nebedėvimų ir jums nebeaktualių drabužių, joje vyrauja chaosas, ir kol
kas dar niekaip negalite apsispręsti, kokio pokyčio norite, trūksta aiškumo ir krypties. Tam
rekomenduoju susisiekti su manimi vilija@karengtis.lt dėl asmeninės, gyvai atliekamos spintos
revizijos paslaugos.
      </Text>
      </AccordionPanel>
    </AccordionItem>
    <AccordionItem>
      <h2>
        <AccordionButton>
          <Box as="span" flex='1' textAlign='left' fontWeight='bold'>
          Ką daryti, jeigu dabar noriu kokybiškai ir tikslingai apsipirkti? Vien teorinių patarimų man
          neužtenka, o daiktų nuorodų jūs nesiunčiate.
          </Box>
          <AccordionIcon />
        </AccordionButton>
      </h2>
      <AccordionPanel pb={4}>
      <Text fontSize='lg'>
      Galimas ir gyvai atliekamas asmeninis apsipirkimas kartu vaikštant po fizines parduotuves. Kad tai
      kartu suplanuotumėme - susisiekite su manimi vilija@karengtis.lt.
      </Text>
      </AccordionPanel>
    </AccordionItem>
  </Accordion>
    </Card>
    </Center>
    <Box minH='2vw' />
    </>
    )
  }