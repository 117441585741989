import CookieConsent from 'react-cookie-consent';

export default function Consent() {
  return (
    <CookieConsent
      location="bottom"
      cookieName="karengtiscookieconsent"
      expires={30}
      buttonText="Supratau"
      style={{background: '#FB8FFF', minHeight: '78px', paddingTop: '6px'}}
      buttonStyle={{
        background: '#FB8FFF', 
        color: 'white',
        border: '2px solid white'
      }}
    >
      Mes naudojame būtinus slapukus, tam kad galėtume optimizuoti Jūsų patirtį ir suteikti paslaugas
    </CookieConsent>
  );
}