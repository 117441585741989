import {Heading, Text, Box, Center, Card, CardHeader, CardBody, List, ListItem} from "@chakra-ui/react"
import { Helmet } from 'react-helmet';

export default function TermsAndConditions() {
    return (
    <>
    <Helmet>
      <title>Ką rengtis pagal Viliją Rušinskaitę - Sąlygos ir nuostatos</title>
    </Helmet>
    <Box minH='2vw' />
    <Center>
    <Card border='1px' maxWidth="80vw" minWidth='60vw' shadow='none'>
      <CardHeader >
      <Box minH='1vw' /> 
        <Center>
          <Heading as='h1' sx={{ fontSize: '32px', fontFamily: 'Montserrat, sans-serif', textAlign: 'center' }}>Sąlygos ir nuostatos</Heading>
        </Center>
      </CardHeader>
      <CardBody pt='0'>
      <List spacing={3} 
      pl={5} 
      mt={0}
      >
      <ListItem><Text pt='0' fontSize='lg' fontWeight={'bold'}>1. Bendrosios nuostatos</Text></ListItem>

      <ListItem><Text fontSize='lg'>1.1. Platforma "Ką rengtis" (toliau – Platforma) yra internetinė paslauga, skirta individualioms stiliaus konsultacijoms teikti.</Text></ListItem>

      <ListItem><Text fontSize='lg'>1.2. Naudodamiesi Platforma, Jūs sutinkate su šiomis Naudojimo taisyklėmis ir patvirtinate, kad susipažinote su jų sąlygomis.</Text>
      </ListItem>

      <ListItem><Text fontSize='lg'>1.3. Platformos administratorius turi teisę bet kada pakeisti šias Taisykles, o pakeitimai įsigalioja juos paskelbus Platformoje.</Text></ListItem>
      </List>
      <List spacing={3} 
      // styleType="disc" 
      pl={5} mt={4}>
      <ListItem><Text pt='2' fontSize='lg' fontWeight={'bold'}>2. Paslaugų teikimo tvarka</Text></ListItem>

      <ListItem><Text fontSize='lg'>2.1. Platforma teikia individualias stiliaus konsultacijas, kurias vykdo profesionalūs stilistai.</Text></ListItem>

      <ListItem><Text fontSize='lg'>2.2. Registruodamiesi Platformoje, vartotojas (toliau – Naudotojas) pateikia savo el. paštą ir sukuria paskyrą.</Text>
      </ListItem>

      <ListItem><Text fontSize='lg'>2.3. Naudotojas gali pateikti stiliaus užklausas, kurios turi apimti:</Text></ListItem>
      <List spacing={3} 
      styleType="disc" 
      pl={10} mt={4}>
      <ListItem><Text fontSize='lg'>Pavadinimą,</Text></ListItem>
      <ListItem><Text fontSize='lg'>Detalų užklausos tekstą,</Text></ListItem>
      <ListItem><Text fontSize='lg'>Prisegtas nuotraukas.</Text></ListItem>
      </List>
      <ListItem><Text fontSize='lg'>2.4. Stilistė atsako į užklausą ne vėliau kaip per 2 darbo dienas nuo jos pateikimo.</Text></ListItem>
      <ListItem><Text fontSize='lg'>2.5. Stilistės atsakymas apima tekstinį atsakymą ir, jei reikalinga, rekomenduojamų aprangos sprendimų nuotraukas.</Text></ListItem>
      <ListItem><Text fontSize='lg'>2.6. Naudotojas apie atsakytą užklausą informuojamas el. paštu. Atsakymas taip pat pasiekiamas Naudotojo paskyroje.</Text></ListItem>
      </List>
      <List spacing={3} 
      // styleType="disc" 
      pl={5} mt={4}>
      <ListItem><Text pt='2' fontSize='lg' fontWeight={'bold'}>3. Mokėjimo tvarka</Text></ListItem>

      <ListItem><Text fontSize='lg'>3.1. Paslaugos apmokamos banko kortele per Platformoje integruotą mokėjimo sistemą.</Text></ListItem>

      <ListItem><Text fontSize='lg'>3.2. Mokėjimo suma ir užklausų kainos nurodytos Platformos kainoraštyje.</Text>
      </ListItem>

      <ListItem><Text fontSize='lg'>3.3. Mokėjimas turi būti atliktas pateikiant užklausą. Tik apmokėtos užklausos yra perduodamos stilistės peržiūrai.</Text></ListItem>
      </List>
      <List spacing={3} 
      pl={5} mt={4}>
      <ListItem><Text pt='2' fontSize='lg' fontWeight={'bold'}>4. Naudotojo atsakomybė</Text></ListItem>

      <ListItem><Text fontSize='lg'>4.1. Naudotojas privalo pateikti tikslią ir aiškią informaciją savo užklausose.</Text></ListItem>

      <ListItem><Text fontSize='lg'>4.2. Naudotojas atsakingas už prisegtų nuotraukų turinį, kuris neturi pažeisti teisės aktų ar trečiųjų šalių teisių.</Text>
      </ListItem>

      <ListItem><Text fontSize='lg'>4.3. Naudotojas įsipareigoja neplatinti Platformos stilistės atsakymų viešai ar komerciniams tikslams be rašytinio sutikimo.</Text></ListItem>
      </List>
      <List spacing={3} 
      pl={5} mt={4}>
      <ListItem><Text pt='2' fontSize='lg' fontWeight={'bold'}>5. Platformos atsakomybė</Text></ListItem>

      <ListItem><Text fontSize='lg'>5.1. Platforma įsipareigoja teikti kokybiškas paslaugas pagal Naudotojo pateiktą informaciją.</Text></ListItem>

      <ListItem><Text fontSize='lg'>5.2. Platforma neatsako už Naudotojo pateiktos informacijos klaidas ar netikslumus, kurie gali turėti įtakos stilistės atsakymo kokybei.</Text>
      </ListItem>

      <ListItem><Text fontSize='lg'>5.3. Platforma neatsako už galimus techninius nesklandumus, kurie nepriklauso nuo jos kontrolės.</Text></ListItem>
      </List>
      <List spacing={3} 
      pl={5} mt={4}>
      <ListItem><Text pt='2' fontSize='lg' fontWeight={'bold'}>6. Privatumo politika</Text></ListItem>

      <ListItem><Text fontSize='lg'>6.1. Naudotojo asmeninė informacija tvarkoma laikantis Bendrojo duomenų apsaugos reglamento (BDAR) reikalavimų.</Text></ListItem>

      <ListItem><Text fontSize='lg'>6.2. Naudotojo el. paštas naudojamas tik komunikacijai, susijusiai su Platformos paslaugomis.</Text>
      </ListItem>

      <ListItem><Text fontSize='lg'>6.3. Išsami informacija apie privatumo politiką pateikiama atskirame Privatumo politikos dokumente.</Text></ListItem>
      </List>
      <List spacing={3} 
      pl={5} mt={4}>
      <ListItem><Text pt='2' fontSize='lg' fontWeight={'bold'}>7. Baigiamosios nuostatos</Text></ListItem>

      <ListItem><Text fontSize='lg'>7.1. Visi ginčai, kylantys dėl šių taisyklių ar paslaugų teikimo, sprendžiami derybų keliu. Nepavykus susitarti, ginčai nagrinėjami pagal Lietuvos Respublikos teisės aktus.</Text></ListItem>

      <ListItem><Text fontSize='lg'>7.2. Naudotojas, turėdamas klausimų ar nusiskundimų, gali kreiptis el. paštu: pagalba@karengtis.lt.</Text>
      </ListItem>
      </List>
      </CardBody>
    </Card>
    </Center>
    <Box minH='2vw' />
    </>
    )
  }