import {Box, Center, Heading, Text, CardBody, CardHeader, Card} from "@chakra-ui/react";
import {Link} from "react-router-dom";
import { Helmet } from 'react-helmet';


export default function NotFound() {
  return (
      <>
    <Helmet>
      <title>Ką rengtis pagal Viliją Rušinskaitę - Puslapis nerastas</title>
    </Helmet>
    <Box minH='2vw' />
    <Center>
    <Card border='1px' maxWidth="80vw" minWidth='60vw' shadow='none'>
      <CardHeader >
      <Box minH='1vw' />
        <Center>
          <Heading as='h1' sx={{ fontSize: '32px', fontFamily: 'Montserrat, sans-serif' }} >Oops!</Heading>
        </Center>
      </CardHeader>
      <CardBody>
        <Box>
          <Text pt='2' fontSize='xl'>
          Atrodo, šis puslapis neegzistuoja :/
          </Text>
          <Box minH='1vw' />
          <Text pt='2' fontSize='xl' display='inline'>
            Gal pabandyti iš naujo sugrįžus į&nbsp;
            <Link to='/'> 
              <Text pt='2' fontSize='xl' color='#FB8FFF' cursor='pointer' display='inline'>
                pradinį puslapį
              </Text>
            </Link>?
          </Text>
          <Box minH='1vw' />
        </Box>
    <Box minH='2vw' />
      </CardBody>
    </Card>
    </Center>
    </>
  )
}