import {LoginForm} from "@userfront/toolkit/react";
import {Box, Center, Button, Text, useMediaQuery} from "@chakra-ui/react";
import {Link, useLocation} from "react-router-dom";
import Userfront from "@userfront/toolkit/react";
import { Helmet } from 'react-helmet';
// import React, {useState, useEffect} from "react";
Userfront.init("wbmg59gb");


export default function Login() {
  const [isSmallScreen] = useMediaQuery("(max-width: 30em)"); // Small screen (less than 30em)
  // const [isMediumScreen] = useMediaQuery("(min-width: 80em)"); // Adjust the breakpoint as needed
  // const [isLoading, setIsLoading] = useState(true);

  const location = useLocation();

  const shouldHideButton = location.pathname === "/login" && location.search;

  const queryParams = new URLSearchParams(location.search);
  const isVerifyType = queryParams.get('type') === 'verify';

  // useEffect(() => {
  //   async function initializeUserfront() {
  //     try {
  //       await Userfront.init("wbmg59gb");
  //       setIsLoading(false);
  //     } catch (error) {
  //       console.log("Error initializing Userfront:", error);
  //       setIsLoading(false);
  //     }
  //   }

  //   initializeUserfront();
  // }, []);

  // useEffect(()=>{
  //   console.log("Userfront initialized:", !isLoading);
  // }, [isLoading]);

  return (
    <> 
    <Helmet>
      <title>Ką rengtis pagal Viliją Rušinskaitę - Prisijungti</title>
    </Helmet>
      <Box minH='2vw' />
      <Center/>
      {/* <div className={isLoading ? "" : "initialized"}> */}
      <LoginForm />
      {/* </div> */}
      {isSmallScreen ? (
        <Box minH='3vw' />
      ) : <Box minH='1vw' />}
      {/* {isMediumScreen ? (
        <Box minH='1vw' />
      ) : null} */}
      <Center>
      {!isVerifyType && (
        <>
      <Text textAlign='center' fontSize='xl' display='inline'>Dar neturi paskyros?&nbsp;</Text>
      <Link to="/signup">
        <Text fontWeight="bold" fontSize='xl' display='inline' color="#FB8FFF">Registruokis</Text>
      </Link>
      </>
      )}
      </Center>
      {isSmallScreen ? (
        <Box minH='3vw' />
      ) : <Box minH='1vw' />}
      {/* {isMediumScreen ? (
        <Box minH='1vw' />
      ) : null} */}
      <Center>
      <Link to="/reset">
        {!shouldHideButton && (
          <Button
            colorScheme='white' 
            color='black.500'
            borderColor='black.500'
            borderWidth='2px'
            variant='outline'
            sx={{
              '&:hover': {
                bg: "rgba(251, 143, 255, 0.4)"
              },
            }}
            minW='340px'>Priminti slaptažodį</Button>
        )}
      </Link>   
      </Center>
      <Box minH='2vw' />   
    </>
  );
}