import {Heading, Text, Box, Center, Card, CardHeader, CardBody} from "@chakra-ui/react"
import { Helmet } from 'react-helmet';

export default function About_pant_length() {
    return (
    <>
    <Helmet>
      <title>Ką rengtis pagal Viliją Rušinskaitę - Apie kelnių ilgį</title>
      <meta name="description" content="Apie kelnių ilgį" />
      <meta property="og:title" content="Apie kelnių ilgį" />
      <meta property="og:description" content="Apie kelnių ilgį" />
      <meta property="og:image" content="https://karengtis.lt/vilija1_small.jpg" />
      <meta property="og:url" content="https://www.karengtis.lt/apie" />
    </Helmet>
    <Box minH='2vw' />
    <Center>
    <Card border='1px' maxWidth="80vw" minWidth='60vw' shadow='none'>
      <CardHeader >
        <Box minH='1vw' />
        <Center>
          <Heading as='h1' sx={{ fontSize: '32px', fontFamily: 'Montserrat, sans-serif' }}>Apie kelnių ilgį</Heading>
        </Center>
      </CardHeader>
    <Center>
    <CardBody pt='0'>
        <Box>
          <Text pt='0' fontSize='xl'>
          Per savo ilgą klientų aprengimo karjerą parduotuvių matavimosi kabinose
esu dalyvavusi įvairiuose aptarimuose ir vertinimuose apie drabužio gulimą
ant kūno. Tuose pokalbiuose gimsta tiesa arba bent jau apsikeitimas savo
realybės vertinimu. Bet jeigu pokalbis pakrypsta panašia linkme kaip "visai
gražiai man čia ir per pečius, ir nugaroj. Spalva labai patinka, tik rankovės
per trumpos" - tai dažniausiai reiškia, kad buvo priimtas aiškus ir
neginčytinas sprendimas to drabužio dėvėtojui ir pirkėjui. Per trumpos
rankovės, nepriklausomai nuo pasikaustymo mados lauke, amžiaus ir
gyvenimo patirties yra universaliai nepageidaujama ir netoleruotina situacija.
Kaip koks prie batų prikibęs tualetinis popierius. Mane ši rankovių ilgio
vertinimo konstanta net savotiškai ramina pasaulyje, kuriame viskas nuolat
kinta milžinišku greičiu. Tik kodėl tai universaliai neatsispindi perkant kelnes?
Jų ilgis taip pat yra iškalbingas ir principingai pasirinktos ne per trumpos
kelnės visą įvaizdį gali kilstelti į sofistikuotą arba bent jau adekvatų,
bendražmogiškai kultūringą lygį. O mes juk kultūringi žmonės? Noriu jums
padėti priimti sau naudingesnius sprendimus kitą kartą perkant džinsus,
kelnes, ar kombinezoną. Visas reikalas yra tikrai nesudėtingas.
          </Text>
          <Box minH='1vw' />
          <Text pt='2' fontSize='xl' display='inline' fontWeight="bold">
          Geras kelnių ilgis:</Text><Text  fontSize='xl' display='inline'> matuojantis naujas kelnes būtinai apsiaukit batus, nes su
batais kelnės truputį patrumpėja, o tai ir bus tikrasis jų ilgis, kai jūs tas kelnes
dėvėsit - klešnės apačia turi šiek tiek užkristi ant batų. Ne tik juos lengvai
brūkštelėti ar susimojuoti pagarbiu atstumu, bet patogiai ir meiliai apsikabinti
su batais. Kad žengiant žingsnį kelnės liūdnai neplevėsuotų ore sau, o batai
ir kojinės nešvytuotų sau. Su pakankamo ilgio kelnėmis ir kojinių derinimas
paprastesnis, nereikia vesti sudėtingų formulių.
</Text>
<Text pt='2' fontSize='xl'>
Jeigu norit plačių kostiuminių kelnių galingo smart casual įvaizdžio -
matuokitės jas su aukštakulniais, kurie dar daugiau patrumpina kelnes, o tai
reiškia, kad kelnes turėsit rinktis dar ilgesnes negu su lygiapadžiais arba jas
teks nunešti atlenkti siuvėjai. Bet ši misija - adekvatus kelnių ilgis - tikrai yra
įmanoma. Visai kaip su rankovėmis, tik išlikit principingi ir nepasiduokit vien
gražaus gulimo aplink juosmenį ir klubus vilionėms. Bendras vaizdas iki pat
"padelkų" turi būti harmoningas.
          </Text>
          <Box minH='1vw' />
          <Text pt='2' fontSize='xl' display='inline' fontWeight="bold">
          Yra šitame klausime išimčių:</Text><Text  fontSize='xl' display='inline'> "kick flare" stiliaus kelnės turi būti per
trumpos, 7/8 ilgio. Taip pat yra mėgstančių"awkward" ilgio kelnes ir jas
puikiai derinančių. Bet kaip ir su visų taisyklių laužymu - jas rekomenduoju
pirma gerai išmokti, ir tik kai jau pasidarys nuobodu ir norėsis naujų iššūkių,
nes gyvenime gal šiuo metu jų turite per mažai - bandykit "awkward" ilgio
kelnes
</Text>
<Text pt='2' fontSize='xl'>
Dar yra bridžai, bermuda šortai, 3/4 ilgio tamprės, kurie yra legaliai per
trumpų kelnių statuse. 
          </Text>
          <Box minH='1vw' />
          <Text pt='0' fontSize='xl' display='inline'>
          Yra ir vienas </Text><Text pt='2' fontSize='xl' display='inline' fontWeight="bold">pavojingas siluetas</Text><Text pt='0' fontSize='xl' display='inline'>, kuris lyg tyčia daugeliui nugula kažkur per
blauzdos vidurį - nepadoriai per trumpame ilgyje. Tai yra mom fit džinsai.
Tiesiog kitų džinsų paieškų metų venkit šito silueto, nes jis deriniams ir
siluetui nieko neduoda proporciškai, tik apvagia. 
          </Text>
          <Box minH='1vw' />
          <Text pt='2' fontSize='xl' display='inline' fontWeight="bold">
          Alternatyva</Text><Text  fontSize='xl' display='inline'> - aukšto juosmens wide fit džinsai, itin platūs slouchy džinsai,
barrel leg džinsai (irgi mėgstantiems šiokius tokius stiliaus iššūkius) arba visų
figūrų padailintojai ir palaikytojai - flare džinsai. Tobulai prigludę aplink
sėdmenis, juosmenį ir šlaunis, o nuo kelio drastiškai išplatėjantys, ekstra ilgi
ir dieviškai atrodantys su smailianosiais. </Text>
          <Box minH='1vw' />
          <Text pt='0' fontSize='xl'>
          Tikiuosi, kitų kelnių medžioklės metu būsit reiklesni ilgiui. Arba jeigu norit
praktinės ilgių atsirinkimo pamokos - visada į matavimosi kabiną galit
pasikviesti mane. Tegu tai bus mano metų misija - Lietuva su pakankamo
ilgio kelnėm.
          </Text>
          <Box minH='1vw' />
        </Box>
    </CardBody>
    </Center>
    </Card>
    </Center>
    <Box minH='2vw' />
    </>
    )
  }