import {Heading, Text, Box, Center, Card, CardHeader, CardBody, List, ListItem} from "@chakra-ui/react"
import { Helmet } from 'react-helmet';

export default function Privacy() {
    return (
    <>
    <Helmet>
      <title>Ką rengtis pagal Viliją Rušinskaitę - Privatumo politika</title>
    </Helmet>
    <Box minH='2vw' />
    <Center>
    <Card border='1px' maxWidth="80vw" minWidth='60vw' shadow='none'>
      <CardHeader >
      <Box minH='1vw' />
        <Center>
          <Heading as='h1' sx={{ fontSize: '32px', fontFamily: 'Montserrat, sans-serif', textAlign: 'center'  }} >Privatumo politika</Heading>
        </Center>
      </CardHeader>
      <CardBody pt='0'>
        <Box>
          <Text pt='0' fontSize='lg'>
          Ši Privatumo politika aprašo, kaip mes (toliau - „Platforma“) renkame, naudojame ir dalijamės Jūsų asmenine informacija, kai lankotės mūsų svetainėje arba pateikiate užklausą stilistei.
</Text><Text pt='4' fontSize='lg' fontWeight='bold'>
Asmeninės informacijos rinkimas
</Text><Text pt='2' fontSize='lg'>
Kai lankotės mūsų svetainėje arba naudojatės mūsų paslaugomis, mes renkame tam tikrą informaciją apie Jūsų įrenginį, sąveiką su svetaine ir informaciją, reikalingą Jūsų užklausoms ar pirkimams apdoroti.
</Text><Text pt='4' fontSize='lg' fontWeight='bold'>
Informacija apie įrenginį:
</Text><List spacing={3} styleType="disc" pl={5} mt={4}>
<ListItem><Text fontSize='lg'>Surinkta informacija: naršyklės versija, IP adresas, laiko juosta, slapukų informacija, lankyti puslapiai ir produktai, paieškos terminai, sąveika su svetaine.</Text></ListItem>

<ListItem><Text fontSize='lg'>Rinkimo tikslas: svetainės tinkamas veikimas ir naudojimo analizė.</Text></ListItem>

<ListItem><Text fontSize='lg'>Surinkimo šaltinis: automatiškai renkama naudojant slapukus, žurnalus, žymes ar pikselius.</Text></ListItem>

<ListItem><Text fontSize='lg'>Informacijos pasidalinimas: dalijamės su paslaugų teikėjais, tokiais kaip platformos prieglobos tiekėjai.</Text></ListItem>
</List>
<Text pt='4' fontSize='lg' fontWeight='bold'>
Užklausų informacija:
</Text><List spacing={3} styleType="disc" pl={5} mt={4}>
<ListItem><Text fontSize='lg'>Surinkta informacija: vardas, el. pašto adresas, stilisto konsultacijos pageidavimai bei susijusios fotografijos.</Text></ListItem>

<ListItem><Text fontSize='lg'>Rinkimo tikslas: stilisto konsultacijų teikimas.</Text></ListItem>

<ListItem><Text fontSize='lg'>Rinkimo šaltinis: Jūs pateikiate šią informaciją per svetainę.</Text></ListItem>

<ListItem><Text fontSize='lg'>Informacijos pasidalinimas: naudojama vidiniams poreikiams ir paslaugoms teikti.</Text></ListItem>
</List>
<Text pt='4' fontSize='lg' fontWeight='bold'>
Mokėjimų informacija
</Text><Text pt='2' fontSize='lg'>Kai atliekate mokėjimą per mūsų svetainę, mes renkame informaciją, reikalingą mokėjimui apdoroti:</Text>
<List spacing={3} styleType="disc" pl={5} mt={4}>
<ListItem><Text fontSize='lg'>Surinkta informacija: mokėjimo kortelės duomenys (kortelės numeris, galiojimo data, CVC kodas), vardas, pavardė, mokėjimo adresas, el. pašto adresas, IP adresas.</Text></ListItem>

<ListItem><Text fontSize='lg'>Rinkimo tikslas: mokėjimų apdorojimas, sukčiavimo prevencija ir teisinių įsipareigojimų vykdymas.</Text></ListItem>

<ListItem><Text fontSize='lg'>Trečiosios šalys: mokėjimai apdorojami naudojant Stripe paslaugą. Stripe taip pat gali rinkti ir naudoti Jūsų informaciją pagal savo privatumo politiką, kurią galite rasti čia: https://stripe.com/privacy.</Text></ListItem>

<ListItem><Text fontSize='lg'>Teisinis pagrindas: mokėjimų informacija tvarkoma sutarties vykdymo pagrindu arba remiantis teisėtais mūsų interesais užtikrinti mokėjimų saugumą.</Text></ListItem>
</List>
<Text pt='4' fontSize='lg' fontWeight='bold'>
Asmeninių duomenų naudojimas
</Text><Text pt='2' fontSize='lg'>
Mes naudojame Jūsų asmeninę informaciją tam, kad:
</Text><List spacing={3} styleType="disc" pl={5} mt={4}>
<ListItem><Text fontSize='lg'>suteiktume paslaugas, susijusias su stilisto konsultacijomis;</Text></ListItem>

<ListItem><Text fontSize='lg'>susisiektume su Jumis dėl Jūsų pateiktų užklausų;</Text></ListItem>

<ListItem><Text fontSize='lg'>tobulintume mūsų svetainės funkcionalumą ir paslaugas.</Text></ListItem>
</List>
<Text pt='4' fontSize='lg' fontWeight='bold'>
Teisinis pagrindas
</Text><Text pt='2' fontSize='lg'>
Vadovaujantis Bendruoju duomenų apsaugos reglamentu (BDAR), mes tvarkome Jūsų asmeninę informaciją remdamiesi:</Text>
<List spacing={3} styleType="disc" pl={5} mt={4}>
<ListItem><Text fontSize='lg'>Jūsų sutikimu;</Text></ListItem>

<ListItem><Text fontSize='lg'>Sutarties vykdymu tarp Jūsų ir Platformos;</Text></ListItem>

<ListItem><Text fontSize='lg'>Teisinėmis prievolėmis.</Text></ListItem>
</List>

<Text pt='4' fontSize='lg' fontWeight='bold'>
Duomenų saugojimas
</Text><Text pt='2' fontSize='lg'>
Jūsų asmeninė informacija bus saugoma tol, kol tai reikalinga numatytiems tikslams pasiekti arba kol Jūs prašysite šalinti šiuos duomenis.
</Text><Text pt='4' fontSize='lg' fontWeight='bold'>
Slapukai
</Text><Text pt='2' fontSize='lg'>
Mes naudojame slapukus, kad pagerintume Jūsų naršymo patirtį. Žemiau pateikiame dažniausiai naudojamų slapukų pavadinimus ir jų funkcijas:
</Text><Text pt='4' fontSize='ml' fontWeight='bold'>
Būtini slapukai svetainės veikimui:</Text>
<List spacing={3} styleType="disc" pl={5} mt={4}>
  <ListItem><Text fontSize='lg'>CONSENT, karengtiscookieconsent: naudojami vartojų informavimui apie slapukų naudojimą.</Text></ListItem>
  <ListItem><Text fontSize='lg'>__stripe_mid, __stripe_sid, __stripe_orig_props, stripe.csrf, site-auth,hubspotutkmachine_identifier, __Secure-has_logged_in, cookie-perms, private_machine_identifier: naudojami mokėjimų surinkimui.</Text></ListItem>
  <ListItem><Text fontSize='lg'>access.wbmg59gb, id.wbmg59gb, refresh.wbmg59gb: naudojami klientų prisijungimui.</Text></ListItem>
  <ListItem><Text fontSize='lg'>cb_user_id, _gcl_au, cb_anonymous_id, messagesUtk, fs_uid, __hssrc, __hstc, cb_group_id, _hp2_id.2471793917, _ga_3VXHZ786F7, hubspotutk: naudojami vartotojų autentikavimui, autorizacijai ir susijusiam funkcionalui. </Text></ListItem>
</List>
<Text pt='4' fontSize='ml' fontWeight='bold'>
Analitikos slapukai:
</Text>
<List spacing={3} styleType="disc" pl={5} mt={4}>
  <ListItem><Text fontSize='lg'>_ga, _ga_D9VLF6FDXJ: Google Analytics slapukai, skirtas svetainės lankomumo analizei.</Text></ListItem>
</List>
<Text pt='2' fontSize='lg'>Mes naudojame tik būtinus „Google Analytics“ slapukus, siekdami užtikrinti minimalų duomenų rinkimą analitikos tikslais.</Text>
<Text pt='2' fontSize='lg'>Slapukų trukmė gali skirtis. Kai kurie slapukai yra laikini (sesijos slapukai) ir ištrinami uždarius naršyklę, o kiti yra nuolatiniai ir lieka Jūsų įrenginyje iki jų galiojimo pabaigos arba kol juos ištrinate.</Text>
<Text pt='2' fontSize='lg'>Jūs galite valdyti slapukų nustatymus savo naršyklėje ir pasirinkti, ar juos priimti, blokuoti arba ištrinti.</Text>
<Text pt='4' fontSize='ml' fontWeight='bold'>
Duomenų dalijimasis
</Text>
<Text pt='2' fontSize='lg'>Mes dalijamės Jūsų asmeniniais duomenimis su šiomis trečiosiomis šalimis, siekdami užtikrinti paslaugų teikimą:</Text>
<List spacing={3} styleType="disc" pl={5} mt={4}>
  <ListItem><Text fontSize='lg'>Stripe: naudojamas mokėjimų apdorojimui. Plačiau apie Stripe privatumo praktiką galite skaityti čia: https://stripe.com/privacy.</Text></ListItem>
  <ListItem><Text fontSize='lg'>Userfront: naudojamas vartotojų autorizacijai ir saugiam prisijungimui prie mūsų platformos. Plačiau apie Userfront privatumo praktiką galite skaityti čia: https://userfront.com/privacy</Text></ListItem>
</List>
<Text pt='4' fontSize='lg' fontWeight='bold'>
Jūsų teisės
</Text><Text pt='2' fontSize='lg'>
Pagal BDAR, Jūs turite teisę:
</Text>
<List spacing={3} styleType="disc" pl={5} mt={4}>
<ListItem><Text fontSize='lg'>susipažinti su savo asmeniniais duomenimis;</Text></ListItem>

<ListItem><Text fontSize='lg'>reikalauti juos ištaisyti, atnaujinti arba ištrinti;</Text></ListItem>

<ListItem><Text fontSize='lg'>prieštarauti asmens duomenų tvarkymui tam tikrais atvejais.</Text></ListItem>
</List>
<Text pt='2' fontSize='lg'>
Dėl savo teisių įgyvendinimo galite kreiptis į mus el. paštu: pagalba@karengtis.lt.</Text>
<Text pt='4' fontSize='lg' fontWeight='bold'>
Privatumo politikos atnaujinimai
</Text><Text pt='2' fontSize='lg'>
Privatumo politika gali būti atnaujinama siekiant atspindėti pasikeitimus mūsų praktikoje arba teisinius reikalavimus. Atnaujinimai bus skelbiami šioje svetainėje.
</Text><Text pt='2' fontSize='lg'>
Paskutinį kartą atnaujinta: 2025-01-01.
          </Text>
        </Box>
      </CardBody>
    </Card>
    </Center>
    <Box minH='2vw'/>
    </>
    )
  }