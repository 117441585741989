import {Heading, Text, Box, Center, Card, CardHeader, CardBody} from "@chakra-ui/react"
import { Helmet } from 'react-helmet';

export default function HowIStyle() {
    return (
    <>
    <Helmet>
      <title>Ką rengtis pagal Viliją Rušinskaitę - Kaip aš rengiu ir stilizuoju</title>
    </Helmet>
    <Box minH='2vw' />
    <Center>
    <Card border='1px' maxWidth="80vw" minWidth='60vw' shadow='none'>
      <CardHeader >
        <Box minH='1vw' />
        <Center>
          <Heading as='h1' sx={{ fontSize: '32px', fontFamily: 'Montserrat, sans-serif' }}>Kaip aš rengiu ir stilizuoju</Heading>
        </Center>
      </CardHeader>
    <Center>
    <CardBody pt='0'>
        <Box>
        <Text pt='0' fontSize='xl'> 
        Per 10 metų savo darbe pastebėjau daug kartų pasitvirtinusią taisyklę: visi žmonės jaučiasi geriau,
užtikrinčiau ir energingiau bei įgauna stipresnį emocinį tonusą, kai jiems patinka tai, kaip jie atrodo
ir kai tai nebūna vienkartinis derinys, o naujas, įgalinantis požiūris be streso į savo stilių. O tada tai
labai greitai pajaučia ir teigiamai atliepia aplinkiniai, ir taip užsisuka pozityvus ratas. Bet vieno
universalaus recepto, patarimo ar pirkinių sąrašo, pritaikomo kiekvienam, tiesiog nėra. Juk
skirtingi esame ne tik amžiumi, ūgiu, kūno proporcijomis, bet ir emocine būkle, dabartiniais
gyvenimo tikslais, gyvenimo būdo intensyvumu, energijos lygiu, pomėgiais, kaip ir kur leidžiame
didžiąją dalį savo dienos laiko ir net tokiu kasdieniu kriterijumi kaip mėgstamos parduotuvės.
Todėl su kiekvienu žmogumi aš pirma bandau išsiaiškinti vidinį ir išorinį, dabartinei situacijai
aktualų poreikį. Kuo tiksliau man nupasakosite ko jums reikia, kas jums patinka, kuo dabar
gyvenate, o gal kaip tik - kas jums itin nepatinka ir yra nepriimtina, tuo lengviau padėsiu jums
pasiekti džiuginantį rezultatą.
        </Text>
        <Text pt='2' fontSize='xl'>
        Auksinės asmeninio stiliaus taisyklės vis dar egzistuoja ir gali būti naudingos kiekvienam derinant
proporcijas, ilgius, ieškant tinkamesnės drabužio struktūros ar tinkančių spalvų asmeniškai jums,
bet jos nėra viskas. Per laiką išlavinant pajautimą, matymą bei supratimą tiek apie tai, kas jums
asmeniškai tinka - nuo spalvų, ilgių, tekstūrų iki siluetų - tiek ir apie pačią madą, jos laikmečio
dvasią, aktualius prekinius mados ženklus ir įtakingesnius, laikui mažiau pavaldžius dizainus, tas
taisykles galima pradėti geriau pritaikyti sau, performuoti pagal poreikį arba apskritai jas laužyti bei
plėsti jų ribas ir prasmes. 
        </Text>
        <Text pt='2' fontSize='xl'>
        Neturint laiko šiai savišvietai gaukite trumpus, personalizuotus stiliaus patarimus, kuriuos jums
rašau pasitelkdama visą savo dešimtmečio patirtį, sukauptą tiek stilizuojant asmeninius klientus -
nuo politikų, iki startuolių įkūrėjų, rašytojų, verslininkų, vestuvių planuotojų, mokslininkų, mamų ir
tėčių, peržiūrint jų spintas ir asmeninių apsipirkimų metu tikslingai jas papildant, tiek dirbant su
komerciniais projektais, žurnalų fotosesijomis, įvairiais Lietuvos mados dizaineriais ir inovatoriais.
        </Text>
        <Text pt='2' fontSize='xl'>
        Kuo aš remiuosi stilizuodama ir konsultuodama klientus? Man patinka dabar vyraujanti
individualistiško ir tuo kiekvienam autentiško stiliaus kultūra. Ji turi daugiausiai prasmės - visi mes
skirtingi, tai kodėl turėtume atrodyti vienodai ar aklai susipirkinėti identiškas kapsulines spintas?
Šia losoja vadovaudamasi su kiekvienu klientu bendrauju pagal individualų poreikį, dėmesingai
ir tikslingai. Ir niekada nesiūlau išsiskirti iš minios vien dėl sportinio intereso, nes visų pirma -
asmeninis stilius turi atliepti jūsų asmeninius interesus ir padėti siekti užsibrėžtų tikslų, kad ir kokie
jie šiuo metu būtų. O antra - dirbimas minios &quot;įdomybių&quot; ir personažų alkio patenkinimui yra
varginantis ir neapdovanojantis, jeigu iš prigimties nesate ekscentriška ir ekstravertiška asmenybė.
Stilius, kaip ir žmonių asmenybės, yra ir turi būti visoks. Tegu jis būna apie jus.
        </Text>
          <Box minH='1vw' />
        </Box>
    </CardBody>
    </Center>
    </Card>
    </Center>
    <Box minH='2vw' />
    </>
    )
  }