import {Heading, Text, Box, Center, Card, CardHeader, CardBody} from "@chakra-ui/react"
import { Helmet } from 'react-helmet';

export default function About() {
    return (
    <>
    <Helmet>
      <title>Ką rengtis pagal Viliją Rušinskaitę - Apie mane</title>
      <meta name="description" content="Apie stilistę Viliją Rušinskaitę" />
      <meta property="og:title" content="Apie mane" />
      <meta property="og:description" content="Apie stilistę Viliją Rušinskaitę" />
      <meta property="og:image" content="https://karengtis.lt/vilija1_small.jpg" />
      <meta property="og:url" content="https://www.karengtis.lt/apie" />
    </Helmet>
    <Box minH='2vw' />
    <Center>
    <Card border='1px' maxWidth="80vw" minWidth='60vw' shadow='none'>
      <CardHeader >
        <Box minH='1vw' />
        <Center>
          <Heading as='h1' sx={{ fontSize: '32px', fontFamily: 'Montserrat, sans-serif' }}>Apie mane</Heading>
        </Center>
      </CardHeader>
    <Center>
    <CardBody pt='0'>
        <Box>
          <Text pt='0' fontSize='xl'>
          Jau 10 metų dirbu mados ir asmenine stiliste. Savo kelią pradėjau nuo asmeninių spintos peržiūrų ir apsipirkimų, netrukus prisidėjo ir kelis metus besitęsusi StiliuSOS 
          internetinių stiliaus konsultacijų era, labai greitai įtraukusi į naujas šio darbo sritis, tokias kaip straipsnių apie stilių rašymas spaudoje ir savo kelis metus aktyviai 
          vestame mados temų apmąstymui skirtame, o dabar jau nebeegzistuojančiame tinklaraštyje "Cippos stilius". Dar prisidėjo pranešimų ir paskaitų apie stilių vedimas, darbas TV laidose
           ir kadre kalbant apie madą, ir už kadro, rengiant jų dalyvius ir vedėjus,  mados ir asmenybių fotosesijų stilizavimo mados ir gyvenimo būdo žurnalams "Aš-Ikona", "Laimė", "Swo",
            "Moteris", "Žmonės", "Lamų slėnis","Stilius" ir dar keletui kitų. Žurnale "Laimė" vienus metus turėjau ir asmeninę skiltį "Kaip stilistė dėvėtų?", kurią kurdama be galo mėgavausi
             ir smaginausi. Kelis metus fokusuodamasi į mados fotosesijų stilizavimą pradėjau dirbti ir su lietuviškais mados ženklais ir kūrėjais, stilizuoti jų reklaminių kampanijų įvaizdžius.
              Dirbau su "Toma Studio", "Silk&Jam", "Dear Freedom", "In Avati", "Natali Silhouette", "Inži". Šiame mados stilistės etape turėjau malonumo tyrinėti, atsirinkti ir naudoti tiek 
              lietuvių, tiek populiarių pasaulio dizainerių ir mados ženklų drabužius bei aksesuarus. Lygiagrečiai pažindinausi su nemažai jaunųjų VDA mados studentų ir jų dažnai labiau eksperimentine,ne tokia komercine kūryba.
              Turėjau galimybę padrąsinti kai kuriuos jų tęsti savo kelią kuriant savo
              prekinius ženklus bei vystant tolimesnę komunikaciją. Man tai yra neįkainojama patirtis. Nuo tada labai palaikau ir populiarinu idėją pirkti daugiau lietuvių dizainerių ir juvelyrų gaminius. 
              Lietuviškos mados kokybė yra puiki,nes kūrėjai sau visada kartelę išsikelia itin aukštai, o tai visada atsispindi vilkint jų kurtus drabužius ir aksesuarus. 
              Per kelis metus darbo vien stilizuojant fotosesijas persisotinau nuo masinio drabužių rinkimo, masinės drabužių gamybos nekokybės, ir po truputį persidėliojusi vertybes aš vėl pilnai grįžau vien prie asmeninio klienčių konsultavimo, spintų peržiūrų ir apsipirkimo. Man asmeninis bendravimas ir 
              poreikių išaiškinimas bei atliepimas spintoje yra prasmingiausia veiklos sritis iš visų išbandytų. Tame pritaikau ir savo psichologijos studijų metu įgytus įgūdžius, ir edukacinę gyslelę. Esu stilizavusi
              daug garsių ir įtakingų žmonių, o taip pat ir jaunų mamų ir moterų iš naujo arba pirmą kartą gyvenime ieškančių savo stilistinės tapatybės. Nuo vestuvinių ir raudono kilimo 
              įvaizdžių iki labai kasdienių poreikių ir situacijų atliepimo. Visa tai apima labai platų spektrą pasiruošimo, patirties, kliento pažinimo, palaikymo ir padrąsinimo ritualų: nuo sapnų 
              paaiškinimo iki suknelės susiglamžymo lygio kelionės metu numatymo. Nuo savų estetinių krypčių ir asmeninę filosofiją atliepiančių prekinių ženklų atrinkimo, iki idealaus pėdkelnių tankumo numatymo. Ir nė viena diena šiame darbe nebūna vienoda ar nuobodi. 
          </Text>
          <Text pt='2' fontSize='xl'>
          Papildomai esu baigusi reklamos strategijos kūrimo mokslus, profesionalios fotografijos bei vizažo kursus. Nuolat mokausi naujų kalbų (šiuo metu mokausi ispanų ir prancūzų) ir įgūdžių. 
          Esu įkūrusi ir mažą, mielą papuošalų prekinį ženklą, kur viską, net maišelius papuošalams kūriau pati. Visada kitus skatinu pirkti mažiau, bet geriau, lokaliau ir atkakliai 
          populiarinu antrų rankų mados kultūrą Lietuvoje, kad vis daugiau jūsų atrastų tiek vintažinių drabužių kokybę, tiek medžioklės džiaugsmą. Esu tvarios mados judėjimo "Fashion revolution" 
          Lietuvoje ambasadorė. 
          </Text>
          <Text pt='2' fontSize='xl'>
          Ir nepaisant visų patirčių, matytų ir liestų kvapą gniaužiančių drabužių ir sukurtų įvaizdžių labiausiai noriu priminti, kad rengimasis neturi būti toks rimtas ir keliantis stresą. Kaip tik rengimasis kiekvieną naują dieną turėtų būti džiaugsmo, motyvacijos ir susicentravimo šaltinis.
           Todėl kuo geriau pažinsite save ir savo tikruosius emocinius, socialinius, psichologinius ir fizinius poreikius, tuo lengviau ir kokybiškiau nusigludins jūsų "signature" stilius. Dar tvirtai tikiu, kad drabužiai turi dirbti mums, 
           o ne mes - jiems, todėl iš manęs nesulauksit entuziastingo spaudimo "išsiskirti iš minios" ar "atkreipti į save dėmesį", nebent patys tokį poreikį jausit ir norėsit, kad padėčiau
            jį atliepti. O kad grožis kasdien vis gelbėja pasaulį ir nuotaiką yra ta priežastis, kodėl manau, kad tai ignoruodami žmonės save truputį nuskriaudžia ir apvagia. Vien dėl to 
            manau, kad asmeninės stiliaus konsultacijos gali ir turi būti prieinamos visiems.
          </Text>
          <Box minH='1vw' />
          <Text pt='2' fontSize='xl'>Šiltai ir padrąsindama,</Text>
          <Box minH='1vw' />
          <Text pt='2' fontSize='xl'>Vilija</Text>
          <Box minH='1vw' />
        </Box>
    </CardBody>
    </Center>
    </Card>
    </Center>
    <Box minH='2vw' />
    </>
    )
  }