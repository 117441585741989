import './App.css';
import React from "react";
import {useState, useEffect} from 'react';
import { useMediaQuery } from '@chakra-ui/react'; // Import necessary components
import { Box, Flex, Spacer, Center, Menu,
  MenuButton,
  MenuList,
  MenuItem,
  useDisclosure,
  Drawer,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  HStack, ButtonGroup, Button, SimpleGrid, Container, Image, Text, Icon, Avatar, IconButton} from "@chakra-ui/react"
import { FaInstagram, FaUser } from "react-icons/fa";
import {EmailIcon, HamburgerIcon} from '@chakra-ui/icons';
import BioModal from './components/dynamic/BioModal';
import Dashboard from './components/dynamic/Dashboard';
import OrderStatus from './components/dynamic/OrderStatus';
import CheckoutForm from './components/dynamic/CheckoutForm';
import Home from './components/static_pages/Home';
import Editor from './components/dynamic/Editor';
import PasswordReset from './components/static_pages/PasswordReset';
import Login from './components/static_pages/Login';
import Signup from './components/static_pages/Signup';
import About from "./components/static_pages/About";
import Blog from './components/static_pages/Blog';
import About_pant_length from './components/static_pages/blog_entries/About_pant_length';
import Prepare_wardrobe_for_spring from './components/static_pages/blog_entries/Prepare_wardrobe_for_spring';
import Pricing from './components/static_pages/Pricing';
import Welcome from './components/static_pages/Welcome';
import FAQ from './components/static_pages/FAQ';
import NotFound from './components/static_pages/NotFound';
import MyRequests from './components/dynamic/MyRequests';
import Privacy from './components/static_pages/Privacy';
import TermsAndConditions from './components/static_pages/TermsAndConditions';
// import Testimonials from './components/static_pages/Testimonials';
import RequireAuth from './components/utils/RequireAuth';
import Consent from './components/dynamic/Consent'; 
import ScrollToTop from  './components/utils/ScrollToTop';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Link
} from "react-router-dom";
import Userfront from "@userfront/toolkit/react";
import HowItWorks from './components/static_pages/HowItWorks';
import HowIStyle from './components/static_pages/HowIStyle';
import Inquiries from './components/static_pages/Inquiries';

// Userfront.init("qbjyj9zn");
// const TENANT = 'qbjyj9zn';
Userfront.init("wbmg59gb");
const TENANT = 'wbmg59gb';

function App() {
  const [isMediumScreen] = useMediaQuery("(min-width: 80em)"); // Medium screen and above
  const [isSmallScreen] = useMediaQuery("(max-width: 30em)"); // Small screen (less than 30em)
  const { isOpen: isDrawerOpen, onOpen: onDrawerOpen, onClose: onDrawerClose } = useDisclosure()
  const { isOpen: isModalOpen, onOpen: onModalOpen, onClose: onModalClose } = useDisclosure()
  const [isEditor, setIsEditor] = useState(false);

  useEffect(() => {
    const dataFetch = async () => {
      const data = await (
        await fetch("https://api.userfront.com/v0/self", {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: 'Bearer ' + Userfront.tokens.accessToken
          }
        })
      ).json();

      if (data?.authorization?.[TENANT]?.roles.includes('editor')) 
        {setIsEditor(true)};
    };

    dataFetch();
}, []);   


  let menu = <></>;
  if (!Userfront.tokens.accessToken) {
  menu =
  <Box
    display='flex'
    alignItems='center'
    justifyContent='center'
    width='100%'
    minHeight='68px'>
  <ButtonGroup gap='2' p='2'>
    <Button 
      // colorscheme='white' 
      color='black.500'
      borderColor='black.500'
      borderWidth='2px'
      variant='outline'
      sx={{
        '&:hover': {
          bg: "rgba(251, 143, 255, 0.4)"
        },
      }} 
    ><Link to="/signup">Registruotis</Link></Button>
    <Button 
          mr='6' 
          colorscheme='white' 
          color='black.500'
          borderColor='black.500'
          borderWidth='2px'
          variant='outline'
          sx={{
            '&:hover': {
              bg: "rgba(251, 143, 255, 0.4)"
            },
          }}
    ><Link to="/login">Prisijungti</Link></Button>
  </ButtonGroup>
  </Box>;
  } else {
    menu = <>
    <Box
    display='flex'
    alignItems='center'
    justifyContent='center'
    width='100%'>
    {isEditor && !isSmallScreen? (<Button 
          mr='6' 
          colorscheme='white' 
          color='black.500'
          borderColor='black.500'
          borderWidth='2px'
          variant='outline'
          sx={{
            '&:hover': {
              bg: "rgba(251, 143, 255, 0.4)"
            },
          }}
    ><Link to="/uzklausos">Atsakyti užklausas</Link></Button>) : null }
    {!isSmallScreen ? ( 
          <Button 
          mr='6' 
          colorscheme='white' 
          color='black.500'
          borderColor='black.500'
          borderWidth='2px'
          variant='outline'
          sx={{
            '&:hover': {
              bg: "rgba(251, 143, 255, 0.4)"
            },
          }}
        >
          <Link to="/mano-uzklausos">Mano užklausos</Link>
        </Button>
    ) : null}
    <Menu isLazy>
    <MenuButton mr='2' colorscheme='pink'>
      <Avatar size='lg' name={Userfront.user.name} src={Userfront.user.image} referrerPolicy="no-referrer">
      </Avatar>
    </MenuButton>
    <MenuList>
      {/* <MenuGroup title='Paskyra'> */}
        <MenuItem onClick={onModalOpen}>
          Apie mane
        </MenuItem>
        <Link to="/reset"><MenuItem>Keisti slaptažodį</MenuItem></Link>
      {isSmallScreen ? (
      <>
        <Link to="/mano-uzklausos"><MenuItem><Text fontWeight="bold">Mano užklausos</Text></MenuItem></Link>
      </>
      ) : null}
      {isSmallScreen && isEditor? (
      <>
        <Link to="/uzklausos"><MenuItem><Text fontWeight="bold">Atsakyti užklausas</Text></MenuItem></Link>
      </>
      ) : null}
        <MenuItem onClick={Userfront.logout}>Atsijungti</MenuItem>
    </MenuList>
  </Menu>  
  <BioModal onModalOpen={onModalOpen} onModalClose={onModalClose} isOpen={isModalOpen}/>
  </Box>
  </>
  }

  return (
    <Flex direction="column" minHeight="100vh"> {/* Flex container for full height */}
    <Box flex="1"> {/* This will take up the remaining space */}
    <Consent />
    <Router>
    <ScrollToTop />
    <div>
    <Flex 
    position='fixed' // Fix the element to the top
    top='0' // Position it at the top
    left='0' // Align it to the left
    right='0' // Stretch it to the right
    zIndex='1000' // Ensure it appears above other elements
    minWidth='max-content' 
    alignItems='center' 
    justifyContent='space-between'
    gap='2' 
    borderBottom='1px' 
    borderColor='black'
    backgroundColor='white'
    minHeight='80px'
     >
    {!isMediumScreen ? (
          <IconButton 
          icon={<HamburgerIcon />} 
          aria-label="Menu" 
          variant="outline" 
          colorscheme='white' 
          color='black.500'
          borderColor='black.500'
          borderWidth='2px'
          onClick={onDrawerOpen}
          sx={{ 
            ml: '12px',
            mr: '12px',
            '&:hover': {
              bg: "rgba(251, 143, 255, 0.4)"
            },
            fontSize: '24px', // Adjust font size
            padding: '16px', // Adjust padding
            minWidth: '48px', // Set a minimum width
            minHeight: '48px', // Set a minimum height
          }}  />
    ) : null}
      <Drawer placement={"left"} onClose={onDrawerClose} isOpen={isDrawerOpen}>
        <DrawerOverlay />
        <DrawerContent>
        <DrawerCloseButton borderBottomWidth='0px' fontSize="18px" pt='4'/>
        <DrawerHeader borderBottomWidth='0px' pt='6'></DrawerHeader>
          <Link onClick={onDrawerClose} to="/apie"><DrawerHeader borderBottomWidth='1px'>Apie mane</DrawerHeader></Link>
          <Link onClick={onDrawerClose} to="/kaina"><DrawerHeader borderBottomWidth='1px'>Kaina</DrawerHeader></Link>
          <Link onClick={onDrawerClose} to="/tinklarastis"><DrawerHeader borderBottomWidth='1px'>Tinklaraštis</DrawerHeader></Link>
          <Link onClick={onDrawerClose} to="/susisiekime"><DrawerHeader borderBottomWidth='1px'>Susisiekime</DrawerHeader></Link>
          {/* <DrawerHeader borderBottomWidth='1px'><Link onClick={onDrawerClose} to="/atsiliepimai">Klientų atsiliepimai</Link></DrawerHeader> */}
        </DrawerContent>
      </Drawer>
      <Box sx={{
        p:'2',
        overflow:'hidden',
        maxW:['45vw', '15vw', '15vw', '15vw',]
      }}>
        <Link to="/">
        <Image src="ka_rengtis_logo_1.png" alt="Ką rengtis?" />
        </Link>
      </Box>
      {isMediumScreen ? (
        <>
      <Box sx={{
        p:'2',
        fontWeight:'bold',
        fontSize: ['12px', '12px', '18px', '24px']
      }}
      ><Link to="/apie">Apie mane</Link></Box>
      <Box sx={{
        p:'2',
        fontWeight:'bold',
        fontSize: ['12px', '12px', '18px', '24px']
      }}
      ><Link to="/kaina">Kaina</Link></Box>
      <Box sx={{
        p:'2',
        fontWeight:'bold',
        fontSize: ['12px', '12px', '18px', '24px']
      }}
      ><Link to="/tinklarastis">Tinklaraštis</Link></Box>
      <Box sx={{
        p:'2',
        fontWeight:'bold',
        fontSize: ['12px', '12px', '18px', '24px']
      }}><Link to="/susisiekime">Susisiekime</Link></Box>
      {/* <Box sx={{
        p:'2',
        fontWeight:'bold',
        fontSize: ['12px', '12px', '18px', '24px']
      }}
      ><Link to="/atsiliepimai">Klientų atsiliepimai</Link></Box> */}
      </>
      ) : null}
      {!isSmallScreen ? (<Spacer />) : null}
      <Box p='2'>
      <Center>
      {isSmallScreen && !Userfront.tokens.accessToken ? ( 
        <>
              <Menu isLazy>
              <MenuButton mr='0' colorscheme='pink'>
                <Avatar size='lg' color='black' bg='white' referrerPolicy="no-referrer" icon={<FaUser color="black"/>}>
                </Avatar>
              </MenuButton>
              <MenuList>
              <Link to="/signup">
                  <MenuItem>
                    Registruotis  
                  </MenuItem>
              </Link> 
              <Link to="/login"><MenuItem>Prisijungti</MenuItem></Link>
              </MenuList>
            </Menu> 
            </>
      ) : menu}
      </Center>   
      </Box>
    </Flex>
    <Box 
    sx = {{ minH: ['82.7vh', '82.7vh', '82.7vh', '82.7vh']
    }}
     bg="rgba(251, 143, 255, 0.2)"
     marginTop={"80px"}
      >
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/apie" element={<About />} />
        <Route path="/tinklarastis" element={<Blog />} />
        <Route path="/tinklarastis/apie_kelniu_ilgi" element={<About_pant_length />} />
        <Route path="/tinklarastis/spintos_paruosimas_pavasariui" element={<Prepare_wardrobe_for_spring />} />
        <Route path="/kaina" element={<Pricing />} />
        <Route path="/kaip-tai-veikia" element={<HowItWorks />} />
        <Route path="/kaip-as-stilizuoju" element={<HowIStyle />} />
        <Route path="/susisiekime" element={<Inquiries />} />a
        {/* <Route path="/atsiliepimai" element={<Testimonials />} /> */}
        <Route path="/duk" element={<FAQ />} />
        <Route path="/salygos-ir-nuostatos" element={<TermsAndConditions />} />
        <Route path="/privatumo-politika" element={<Privacy />} />
        <Route path="/login" element={<Login />} />
        <Route path="/signup" element={<Signup />} />
        {/* prisijungus su google SSO pwd resetas pirma karta acceptina belekoki "sena passworda", o
        kitus kartus resetinant reikia tureti teisinga sena passworda.
        Resetas turi veikti kai eisi neprisijunges */}
        <Route 
          path="/reset" 
          element={
              <PasswordReset />
          } 
        />
        <Route 
          path="/sveiki" 
          element={
            <RequireAuth>
              <Welcome />
            </RequireAuth>
          } 
        />
        <Route 
          path="/apmokejimas" 
          element={
            <RequireAuth>
              <CheckoutForm />
            </RequireAuth>
          } 
        />
        <Route 
          path="/uzsakymo-busena" 
          element={
            <RequireAuth>
              <OrderStatus />
            </RequireAuth>
          } 
        />
        <Route
            path="/uzklausa"
            element={
              <RequireAuth>
                <Dashboard />
              </RequireAuth>
            }
          />
        <Route
            path="/mano-uzklausos"
            element={
              <RequireAuth>
                <MyRequests />
              </RequireAuth>
            }
          />
        <Route
            path="/uzklausos"
            element={
              <RequireAuth>
                <Editor />
              </RequireAuth>
            }
          />
        <Route path='*' element={<NotFound />}/>
      </Routes>
      </Box>
      <SimpleGrid 
        columns={1} // Keep as 1 for a single column layout
        spacing={0} 
        borderTop='1px' 
        borderColor='black'
        as="footer"
      >
        <Container>
          <HStack spacing='48px' justifyContent='center'> {/* Center align the first row */}
            <Box p='2' fontWeight='bold'
            sx={{ 
              fontSize: ['sm', 'lg', 'lg', 'lg'], }}
            ><Link to="/salygos-ir-nuostatos">Sąlygos ir nuostatos</Link></Box>
            <Box p='2' fontWeight='bold' 
            sx={{ 
              fontSize: ['sm', 'lg', 'lg', 'lg'], }}><Link to="/privatumo-politika">Privatumo politika</Link></Box>
            <Box p='2' fontWeight='bold'
            sx={{ 
              fontSize: ['sm', 'lg', 'lg', 'lg'], }}><Link to="/duk">D.U.K.</Link></Box>
          </HStack>
        </Container>
        <Container>
          <HStack spacing='24px' justifyContent='center'> {/* Center align the second row */}
            <HStack  sl='2' sr='2' sb='2' pl='2' pr='2' pb='2'> {/* Use HStack for horizontal alignment */}
              <EmailIcon/><Text fontSize='ml' fontWeight='bold'
              sx={{ 
                fontSize: ['sm', 'lg', 'lg', 'lg'], }}> vilija@karengtis.lt</Text>
            </HStack>
              <HStack sl='2' sr='2' sb='2' pl='2' pr='2' pb='2'> {/* Use HStack for horizontal alignment */}
                <Icon as={FaInstagram} boxSize={5} fontWeight='semibold'/>
                <Link to="https://www.instagram.com/karengtis.lt/">
                  <Text fontSize='ml' fontWeight='bold'
                  sx={{ 
                    fontSize: ['sm', 'lg', 'lg', 'lg'], }}> @karengtis.lt</Text>
                </Link>
              </HStack>
          </HStack>
        </Container>
      </SimpleGrid>
    </div>
  </Router>
  </Box>
  </Flex>
  );
}

export default App;
