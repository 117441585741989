import {Heading, Text, Box, Center, Card, CardHeader, CardBody} from "@chakra-ui/react"
import { Helmet } from 'react-helmet';

export default function Prepare_wardrobe_for_spring() {
    return (
    <>
    <Helmet>
      <title>Ką rengtis pagal Viliją Rušinskaitę - Spintos paruošimas pavasariui</title>
      <meta name="description" content="Spintos paruošimas pavasariui" />
      <meta property="og:title" content="Spintos paruošimas pavasariui" />
      <meta property="og:description" content="Spintos paruošimas pavasariui" />
      <meta property="og:image" content="https://karengtis.lt/vilija1_small.jpg" />
      <meta property="og:url" content="https://www.karengtis.lt/apie" />
    </Helmet>
    <Box minH='2vw' />
    <Center>
    <Card border='1px' maxWidth="80vw" minWidth='60vw' shadow='none'>
      <CardHeader >
        <Box minH='1vw' />
        <Center>
          <Heading as='h1' sx={{ fontSize: '32px', fontFamily: 'Montserrat, sans-serif' }}>Spintos paruošimas pavasariui</Heading>
        </Center>
      </CardHeader>
    <Center>
    <CardBody pt='0'>
        <Box>
          <Text pt='0' fontSize='xl'>
          Pavasaris jau čia (pagaliau!), o su juo kartu ir pokyčiai, reikalaujantys
emocinio, fizinio ir psichologinio prisitaikymo. Skamba kaip daug darbo, bet
tereikia leisti tam įvykti.
          </Text><Text pt='0' fontSize='xl'>
          Rituališkai atsinaujinimą kasmet pradedu nuo namų generalinio švarinimo ir
spintos paruošimo naujam lengvumo, išsilukštenimo ir suaktyvėjimo etapui.
Tik nebūtina spintos atšviežinimo daryti grandiozinių apimčių ir intensyvumo.
Nes ir pats pavasarėjimas Šiaurėje turi savo lėtą ir inertišką tempą. Tai nuo
ko pradėti?
          </Text>
          <Box minH='1vw' />
          <Text pt='2' fontSize='xl' display='inline' fontWeight="bold">
          Iš spintos</Text><Text  fontSize='xl' display='inline'> išimkit kailinius, storus vilnonius paltus, verstus puskailinius,
pūkinės striukes, sintetinius kailinukus, storus vilnos megztinius, ilgas
megztas sukneles ir sijonus, golfus. Taip pat šalikus, kepures ir pirštines. O
galiausiai ir žieminius batus. Įvertinkit, kuriuos reikia išskalbti, kuriuos
išvėdinti saulėkaitoje, kuriuos nunešti į valyklą ar pas batsiuvį, kad atėjus
kitam rudeniui viskas būtu švaru ir paruošta, be atidėtų pataisymų ir kitų
galvosūkių. Visus žieminius daiktus supakuokit ir pasėpkit atskirai, o jeigu
neturit tokios galimybės - įvilkit į drabužių maišus (idealu - nepermatomus) ir
pakabinkit į tolimiausią drabužinės kampą, kad nesimaišytų ir nematytumėt
jų per vasarą. 
</Text>
          <Box minH='1vw' />
          <Text pt='2' fontSize='xl' display='inline' fontWeight="bold">
          Kitas etapas</Text><Text  fontSize='xl' display='inline'> - peržiūrint žieminius drabužius ir batus kritiškai įvertinkit jų
būklę. Tekstilė nėra amžina, viskas kažkada susidėvi, ir neturiu minty
sudėvėjimo iki skylių. Jeigu drabužis jau praradęs formą, susitraukęs,
išmuštais keliais, išblukęs, papilkėjęs ar pageltęs, suplonėjęs ar per daug
nusiburbuliavęs. Arba net jei su jo būkle viskas gerai, bet per metus
neapsivilkot nė karto - turit arba parduoti, jeigu dar tikrai geros būklės be
vizualiai matomų pakitimų, arba perleisti, jeigu turit kam, arba išnešti
perdirbimui ar išmetimui. Vis laikant ir gailint gerų, bet nebedėvimų ir
nebemylimų drabužių save apkraunat ir gerokai apsunkinat apsirengimo
procesą. Drabužiais atsikratyti yra tikrai legalu, jeigu jie nebeatitinka
dabartinių jūsų fizinių, socialinių, profesinių, estetinių ar emocinių poreikių.
Nors mamos ir močiutės mus auklėjo branginti ir vertinti savo daiktus kartais
šis išmokimas pereina į kalte apipintą kaupimą, nes fone veikia viską
miglotai, bet teisuoliškai prie "gal dar prireiks" privedanti programa. Nors juk
tikrai žinot, kad neprireiks. Tik gal nemalonu ir gal liūdna tai sau pripažinti.
Bet gyvenimas tęsiasi, nuolat keičiasi. Jūs jame - irgi. Leiskit savo spintai po
truputį keistis kartu su jumis. 
</Text>
          <Box minH='1vw' />
          <Text pt='0' fontSize='xl' display='inline'>
          Atlikus visus apsivalymo ir išsišvarinimo ritualus </Text><Text pt='2' fontSize='xl' display='inline' fontWeight="bold">į spintą grąžinkit</Text><Text pt='0' fontSize='xl' display='inline'> lengvus
lauko drabužius: lietpalčius, bomberius, odinius ir džinsinius švarkus. Taip
pat lengvesnius batus: balerinas, vintažinio stiliaus kedus (grubius ir
masyvius palikit žiemai, pavasarį žingsniui leiskit tapt ilgesniam ir
smagesniam), basutes ir slingback batelius. Basučių iki vasaros neatidėliokit,
pėdos nėra tokios jautrios šalčiui kaip kaklas ar galva. Jau nuo 15 ar 16
laipsnių basutes galit autis drąsiai.
          </Text><Text pt='0' fontSize='xl'>
          Taip pat į kasdienę rotaciją drąsiai grąžinkit aksesuarus: saulės akinius,
masyvesnius žiedus, kurie netilpo po pirštinėmis, masyvesnius auskarus,
kurie vis kabinosi už šaliko ar tiesiog visai netiko prie pūkinės striukės,
pakabukus ir vėrinius, kurie pasislėpdavo puriame megztinio glėby, diržus. O
finale - laikas grąžinti lengvesnes, mažesnes rankines trumpesne rankena,
kurios tiesiog nesilaiko ant storų striukės ar kailinių rankovių.  
          </Text>
          <Box minH='1vw' />
          <Text pt='2' fontSize='xl' display='inline' fontWeight="bold">
          Vasarinių ir pliažinių drabužių ir aksesuarų dar neskubėkit iškabinti iki
birželio.</Text><Text  fontSize='xl' display='inline'> Visos nertos kelnės, suknelės, šortai ir rankinės, tie lengvi lininiai
marškiniai, pintos skrybėlės, basutės per pirštą. Visa tai per anksti pasidėjus
matomoje drabužinės vietoje gali tapti tokiu pat pertekliniu ir erzinančiu
negalimu pasirinkimu kaip ir laiku nepatraukti nuo akių kailiniai. </Text>
          <Box minH='1vw' />
          <Text pt='0' fontSize='xl' display='inline'>
          Yra dar vienas spintos etapas - ne etapas. Tai </Text><Text pt='2' fontSize='xl' display='inline' fontWeight="bold">drabužių ir batų kategorija,
          kuri universaliai tinka visus metus</Text><Text pt='0' fontSize='xl' display='inline'> ir su ja nieko ypatingo daryti nereikia.
Nebent reguliariai įsivertinti jų būklę ir ar juos dėvit - visai kaip su žieminiais
drabužiais. Tai yra visokio stiliaus ir atspalvio džinsai, plačios kostiuminės
kelnės, tamprės, bridžai, kulotės, net tamsūs, ne džinsiniai šortai, slip
suknelės ir sijonai, pieštukiniai sijonai, džinsiniai ilgesni sijonai, džinsiniai
marškiniai, vyriško stiliaus marškiniai, kaklaraiščiai, skrybėlės, beretės,
beisbolo kepurės, džemperiai, mini sijonai, midi sijonai, įvairių stilistikų
nepašiltinti ilgaauliai - nuo jojikės, iki baikeriškų ir kaubojiškų. Dar loaferiai,
šilkinės skaros, drabužiai su žvyneliais, pižamos stiliaus kelnių setai,
klasikiniai kelnių ir sijonų kostiumai, tank topai, medvilninių siūlų megztiniai,
plonesni klasikinio silueto kardiganai, polo topai, odinės kelnės ir kelnių
kombinezonai. Odinės rankinės ir kuprinės. Vos jų nepamiršau! 
          </Text><Text pt='0' fontSize='xl'>Visai nemaža grupė nepajudinamų ir ištikimų palydovų per visus metus.</Text>
          <Box minH='1vw' />
          <Text pt='0' fontSize='xl' display='inline'>
          O </Text><Text pt='2' fontSize='xl' display='inline' fontWeight="bold"> kas naujo šiam pavasariui</Text><Text pt='0' fontSize='xl' display='inline'> ir ko verta pasižvalgyti, kai spintoje reikalai
          neblogi, ir rengtis ką yra, bet norisi šiek tiek šviežienos?
          </Text>          
          <Box minH='1vw' />
          <Text pt='0' fontSize='xl'>
          Pasimatuokit ir apsvarstykit sportinio stiliaus lengvesnę, plonesnę, laisvesnę
striukę su užtrauktuku. Ji puikiai poruojasi su puošnesniais sijonais ir
kelnėmis, net su slip ar trumpomis, tiesiomis suknelėmis. Šitą tendenciją
stipriai normalizavo MiuMiu. 
          </Text><Text pt='0' fontSize='xl'>Jeigu toks beformizmas ir neformalumas - ne jums, pasimatuokit workwear
stiliaus striukę su kontrastinga velvetine apykakle. Šis net su kelnių kostiumu
kortų nesumaišys.</Text>
          <Text pt='0' fontSize='xl'>Taip pat tiesų, 90's stiliaus, iki kelių vidurio ar ties kelių apačia pasibaigiantį
sijoną. Ne per daug siaurą. Tokius savo kolekcijose nuolat pristato Prada.
Susagstomą nuo pat kaklo iki vidurio bodį. Gražiai poruojasi su odinėmis
kelnėmis.</Text>
          <Text pt='0' fontSize='xl'>Rankinių skyriuje paieškokit bowling bag stiliaus. Šviežiai tvirta struktūra su
          komfortiška retro forma. Ir baisiai talpi.</Text>
          <Text pt='0' fontSize='xl'>Batų lentynose susiraskit ir pasimatuokit smokingo stiliaus loaferius.
Minkštesni ir draugiškesni pėdoms, o atrodo dar prabangiau už klasikinius,
tvirtus, užapvalintomis formomis loaferius. </Text>
          <Text pt='0' fontSize='xl'>Ir finalui, mano labai mėgstamas palinkėjimas ir paskatinimas suminkštėti ir
sušvelnėti - pasimatuoti ką nors rožinio. Tik rožinė šį pavasarį maišyta su
alyvine. Šalta ir futuristinė. O jeigu rožinė yra ne jūsų spalva - esu tikra, kad
rožinė rankinė ar kedai įterps dozę lengvabūdiškumo ir nerimties net į labai
atsakingos dienos eilutę.</Text>
          <Box minH='1vw' />
        </Box>
    </CardBody>
    </Center>
    </Card>
    </Center>
    <Box minH='2vw' />
    </>
    )
  }