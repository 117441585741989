import {Heading, Text, Box, Center, Card, CardHeader, CardBody} from "@chakra-ui/react"
import { Helmet } from 'react-helmet';

export default function Pricing() {
    return (
    <>
    <Helmet>
      <title>Ką rengtis pagal Viliją Rušinskaitę - Kaina</title>
    </Helmet>
    <Box minH='2vw' />
    <Center>
    <Card border='1px' maxWidth="80vw" minWidth='60vw' shadow='none'>
      <CardHeader >
      <Box minH='1vw' />
        <Center>
          <Heading as='h1' sx={{ fontSize: '32px', fontFamily: 'Montserrat, sans-serif' }} >Kaina</Heading>
        </Center>
      </CardHeader>
      <CardBody paddingTop="0px">
        <Box>
        <Center>
          <Text  fontSize='xl' display='inline'>
          Vienos užklausos kaina yra <Text fontWeight="bold" fontSize='xl' display='inline'>35€</Text>
          </Text>
          </Center>
          <Box minH='2vw' />
        </Box>
      </CardBody>
    </Card>
    </Center>
    <Box minH='2vw' />
    </>
    )
  }