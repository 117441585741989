import React from "react";
import {useState, useEffect} from 'react';
import {Navigate, useNavigate} from "react-router-dom";
import Userfront from "@userfront/toolkit/react";
import {Heading, Text, Box, Center, Card, CardHeader, CardBody, Highlight, Flex, Spinner} from "@chakra-ui/react"
import {Link} from "react-router-dom";
import { Helmet } from 'react-helmet';


const OrderStatus = () => {
  const navigate = useNavigate();
  const [status, setStatus] = useState(null);
  const [customerEmail, setCustomerEmail] = useState('');

  useEffect(() => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const sessionId = urlParams.get('session_id');

    if (!sessionId) {
      navigate("/");
      console.log("sessionId is missing");
    } else {

    // TEST
    // fetch(`https://gchmy3htrh.execute-api.eu-central-1.amazonaws.com/session-status?session_id=${sessionId}`, {
    // PROD
    fetch(`https://6m21o4f9a5.execute-api.eu-central-1.amazonaws.com/session-status?session_id=${sessionId}`, {
      headers: {Authorization: 'Bearer ' + Userfront.tokens.accessToken, 
      "Content-Type": "application/json"}}
    )
      .then((res) => res.json())
      .then((data) => {
        setStatus(data.status);
        setCustomerEmail(data.customer_email);
      });
    }
  }, []);

  const spinner = <>
    <Flex alignContent={"center"} justifyContent={"center"} width={"100vw"} height={"80vh"}>
      <Center>
        <Spinner thickness='4px' speed='0.65s' emptyColor='#E9E9E9' color='#FB8FFF' size='xl'/>
      </Center>
    </Flex>
  </>

  if (status === 'open') {
    return (
      <Navigate to="/apmokejimas" />
    )
  }

  if (status === 'complete') {
    return (
      <>
      <Helmet>
        <title>Ką rengtis pagal Viliją Rušinskaitę - Užklausa gauta!</title>
      </Helmet>
      <Box minH='2vw' />
      {!status? spinner : null}
      <Center>
      <Card border='1px' maxWidth="80vw" minWidth='60vw' shadow='none'>
        <CardHeader >
        <Box minH='1vw' />
          <Center>
            <Heading as='h1' sx={{ fontSize: '32px', fontFamily: 'Montserrat, sans-serif' }} >Užklausa gauta!</Heading>
          </Center>
        </CardHeader>
        <CardBody paddingTop="0px">
          <Box>
            <Text pt='0' fontSize='lg'>
            Ačiū už Jūsų užklausą! Patvirtinimo elektroninis laiškas bus išsiųstas adresu {customerEmail}
            </Text>
            <Box minH='1vw' />
            <Text pt='2' fontSize='lg'>
            Atsakysiu kaip įmanoma greičiau, bet ne ilgiau kaip per dvi darbo dienas. Atsakymo ieškokite skiltyje
             <Link to="/mano-uzklausos">
              <Highlight
                query='"Mano užklausos"'
                styles={{ px: '0', py: '1', rounded: 'full', bg: 'rgba(251, 143, 255, 0.4)' }}
                > "Mano užklausos"
                </Highlight>
              </Link>
             . Kai tik jis bus paruoštas informuosime elektroniniu laišku.
            </Text>
          </Box>
        </CardBody>
      </Card>
      </Center>
      </>
    )
  }

  return null;
}

export default OrderStatus;