import {PasswordResetForm} from "@userfront/toolkit/react";
import {Box, Center} from "@chakra-ui/react"
import { Helmet } from 'react-helmet';

export default function PasswordReset() {
  return (
    <>
    <Helmet>
      <title>Ką rengtis pagal Viliją Rušinskaitę - Slaptažodžio priminimas</title>
    </Helmet>
    <Box minH='2vw' />
    <Center/>
      <PasswordResetForm />
    <Box minH='2vw' />
    </>
  );
}