import React from "react";
import {useState, useEffect} from 'react';
import Userfront from "@userfront/toolkit/react";
import {loadStripe} from '@stripe/stripe-js';
import {useNavigate} from "react-router-dom";
import {
  EmbeddedCheckoutProvider,
  EmbeddedCheckout
} from '@stripe/react-stripe-js';
import { Box, Center, Spinner, Flex} from "@chakra-ui/react"
import { Helmet } from 'react-helmet';

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
// TEST
// const stripePromise = loadStripe("pk_test_51OEumxLR85n50WGrjY4UC3YASVcU2T6gK7CBdmlxnPw0nPIKus9nFTGQe2balaqLW6SGSqlSyoCMbYVUrmdTJRuR00PyIn3Cns");
// PRDO
const stripePromise = loadStripe("pk_live_51OEumxLR85n50WGrgrMQrz1BbsvB82dwqG2UnDjEiSXZjc3ctJSi0LJhIkM0KImKczS02HRizu89mc3mFE0krupe00Bk4qFQMk");

const CheckoutForm = () => {
  // code below validates if provided string is formated as general UUID
  const isHEX = (ch) => "0123456789abcdef".includes(ch.toLowerCase());

  const isGuidValid = (guid) => {
    guid = guid.replaceAll("-", ""); // Format it first!
    return guid.length === 32 && [...guid].every(isHEX);
  };

  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const requestID = urlParams.get('request');
  // console.log(requestID);
  const navigate = useNavigate();
  const [clientSecret, setClientSecret] = useState('');

  if (!requestID) {
    navigate("/");
    console.log("UUID is missing");
  }
  if (requestID && !isGuidValid(requestID)){
    navigate("/");
    console.log("UUID is not valid");
  }

  const spinner = <>
    <Flex alignContent={"center"} justifyContent={"center"} width={"100vw"} height={"80vh"}>
      <Center>
        <Spinner thickness='4px' speed='0.65s' emptyColor='#E9E9E9' color='#FB8FFF' size='xl'/>
      </Center>
    </Flex>
  </>

  useEffect(() => {
    // Create a Checkout Session as soon as the page loads
    // TEST
    // fetch("https://gchmy3htrh.execute-api.eu-central-1.amazonaws.com/create-checkout-session?email=" + Userfront.user.email + "&request=" + requestID + "&user=" + Userfront.user.userUuid, {
    // PROD
    fetch("https://6m21o4f9a5.execute-api.eu-central-1.amazonaws.com/create-checkout-session?email=" + Userfront.user.email + "&request=" + requestID + "&user=" + Userfront.user.userUuid, {
      headers: {Authorization: 'Bearer ' + Userfront.tokens.accessToken, 
      "Content-Type": "application/json"},
      method: "POST",
    })
      .then((res) => res.json())
      .then((data) => setClientSecret(data.clientSecret));
  }, []);

  return (
   <>
    <Helmet>
      <title>Ką rengtis pagal Viliją Rušinskaitę - Apmokėjimas</title>
    </Helmet>
    <div id="checkout">
    <Box minH='2vw' />
    {!clientSecret? spinner : null}
      {clientSecret && (
        <EmbeddedCheckoutProvider
          stripe={stripePromise}
          options={{clientSecret}}
        >
          <EmbeddedCheckout />
        </EmbeddedCheckoutProvider>
      )}
    <Box minH='2vw' />
    </div>
    </>
    )
}

export default CheckoutForm;