import {Heading, Text, Box, Center, Card, CardHeader, CardBody} from "@chakra-ui/react"
import { Helmet } from 'react-helmet';

export default function Inquiries() {
    return (
    <>
    <Helmet>
      <title>Ką rengtis pagal Viliją Rušinskaitę - Susisiekime</title>
    </Helmet>
    <Box minH='2vw' />
    <Center>
    <Card border='1px' maxWidth="80vw" minWidth='60vw' shadow='none'>
      <CardHeader >
      <Box minH='1vw' />
        <Center>
          <Heading as='h1' sx={{ fontSize: '32px', fontFamily: 'Montserrat, sans-serif' }} >Susisiekime</Heading>
        </Center>
      </CardHeader>
      <CardBody pt='0'>
        <Box>
          <Center>
          <Text pt='0' fontSize='xl' >
          Jeigu jūsų asmeninio stiliaus atnaujinimui šiuo metu neužtenka nuotolinių konsultacijų, rašykite
vilija@karengtis.lt<br/>
Galime suplanuoti jūsų spintos peržiūrą, asmeninį apsipirkimą ar aptarti kitas su stiliumi susijusias
bendradarbiavimo galimybes.
          </Text>
          </Center>
          <Box minH='2vw' />
        </Box>
      </CardBody>
    </Card>
    </Center>
    <Box minH='2vw' />
    </>
    )
  }