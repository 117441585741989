import { Card, CardHeader, CardBody, Box, Icon, Text, Heading, Center, Button, Image, Stack } from '@chakra-ui/react'
import {Link} from "react-router-dom";
import React from 'react'; // Import React
import { useMediaQuery } from '@chakra-ui/react'; // Import necessary components
import { ChevronRightIcon } from '@chakra-ui/icons'; // Import an icon
import { Helmet } from 'react-helmet';


export default function Home() {
  const [isSmallScreen] = useMediaQuery("(max-width: 30em)"); // Small screen (less than 30em)
  const [isLargeScreen] = useMediaQuery("(min-width: 160em)"); // Adjust the breakpoint as needed
  const [isMediumScreen] = useMediaQuery("(min-width: 80em)"); // Adjust the breakpoint as needed

  return (
    <>
    <Helmet>
      <meta name="description" content="Personalizuotos stiliaus konsultacijos kiekvienai, kuri nori jaustis savimi" />
      <meta property="og:title" content="Ką rengtis pagal Viliją Rušinskaitė" />
      <meta property="og:description" content="Personalizuotos stiliaus konsultacijos kiekvienai, kuri nori jaustis savimi" />
      <meta property="og:image" content="https://karengtis.lt/vilija1_small.jpg" />
      <meta property="og:url" content="https://www.karengtis.lt" />
    </Helmet>
    <Box minH='2vw'/>
    <Center>
    <Card 
    border='1px' 
    maxWidth="80vw" minWidth='80vw'  direction={{ base: 'column', sm: 'row' }}
  overflow='hidden'>
      <Box 
  display="flex" 
  justifyContent="center" // Center horizontally
  alignItems="center" // Center vertically (if needed)
  height="100%" // Ensure the Box takes full height if needed
  >
    <Image 
      objectFit='cover'
      objectPosition='50% 50%'
      height="50vh"
      maxW={{ base: '80%', sm: '330px', md: '330px' }} // Adjust max width for different screen sizes
      srcSet="
        vilija1_small.jpg 480w,
        vilija1_medium.jpg 800w,
        vilija1_large.jpg 1200w
      "
      sizes="(max-width: 600px) 100vw, (max-width: 1200px) 50vw, 600px"
      src="_E1A5169_large.jpg" // Fallback image
      alt="stiliste Vilija Rusinskaite" 
    />
    </Box>
  <Stack>
  <CardBody p={8} overflow="hidden">
      <Box>
      <Box minH='2vw' />
      {isLargeScreen ? (
        <Box minH='2vw' />
      ) : null}
      {!isMediumScreen && !isSmallScreen? (
        <Box minH='10vw' />
      ) : null}
        <Heading 
          sx={{ 
            fontSize: ['18px', '36px', '36px', '48px'], // Responsive font sizes
            fontFamily: 'Montserrat, sans-serif',
            maxW: '100%', // Set maximum width to 100% of the parent
            overflow: 'hidden', // Hide overflow if necessary
            whiteSpace: 'wrap', // Prevent text wrapping (optional)
            textAlign: ['center', 'center', 'center', 'left'], // Center the text
            textOverflow: 'ellipsis' // Add ellipsis if text overflows (optional)
          }}
          textTransform='uppercase'
        >
        Aprenkite savo asmenybę
        </Heading>
        <Box minH='1vw' />
        {isMediumScreen ? (
        <Text 
        sx={{
          pt: '2',
          fontSize: ['xl', 'xl', 'xl', '2xl']
         }}
        >
        Personalizuotos stiliaus konsultacijos kiekvienai, kuri nori jaustis savimi
        </Text>
        ) : (
          <Box minH='6vw' />
        )}
        {!isSmallScreen ? (<Box minH='2vw' />) : null}
        <Link to="/uzklausa">
          <Button 
          sx={{ 
            fontSize: ['xl', 'xl', '3xl', '4xl'], 
            border: '1px',
            minHeight: ['96px', '156px', '156px', '156px'], // Set a minimum width for the button
            padding: ['24px 48px', '24px 48px', '24px 48px', '48px 96px'],
            whiteSpace: 'wrap', // Prevent text wrapping (optional)
            bg: "rgba(251, 143, 255, 0.2)",
            '&:hover': {
              bg: "rgba(251, 143, 255, 0.4)",
            }
          }}
          >Rašyti užklausą {isMediumScreen ? ('stilistei') : null}</Button>
        </Link>
      </Box>
  </CardBody>
  </Stack>
  </Card>
  </Center>
  <Box minH='2vw' />
  <Center>
  <Card border='1px' maxWidth="80vw" minWidth='60vw' direction={{ base: 'column', sm: 'row' }}
  overflow='hidden'>
    <Stack spacing={0}>
  <CardHeader pb={0}>
    <Box minH='1vw' />
    <Center>
    <Heading sx={{ fontSize: ['24px', '32px', '32px', '32px'], fontFamily: 'Montserrat, sans-serif' }}>Kaip tai veikia?</Heading>
    </Center>
  </CardHeader>
  <CardBody sx={{pt: ['8px', '8px','8px','20px']}}>
      <Box>
        <Text pt='0' sx={{ fontSize: ['ml', 'xl', 'xl', 'xl']}}>
        Parašykite kuo tiksliau jūsų dabartinį įvaizdžio poreikį nusakančią užklausą: kokiu tikslu rengsitės,
ko trūksta jūsų deriniui ar dabartinei stiliaus krypčiai iki pilnos laimės. Jeigu nežinote nuo ko
pradėti ir pavyzdžiui, eisite į vestuves, būtų informatyvu, jeigu parašytumėte renginio laiką (metų
laikas diktuoja pasirinkimus), lokaciją, ar tai didelė šventė svečių kiekio prasme, ar ji turi iš anksto
numatytą vizualią tematiką. Būtinai aprašykite savo visus Už ir Prieš, pavyzdžiui, ar jūs avite
aukštakulnius, ar vilkite mini sijonus, ar mėgstat ryškias spalvas, o gal niekada nesirengiate juodai
ir mėgstat tik natūralų ir minimalistinį makiažą. Kuo tiksliau ir konkrečiau įvardinsite savo poreikius
ir norus užklausoje, tuo tiksliau aš galėsiu sukurti ir pasiūlyti jums tinkančią įvaizdžio idėją.
        </Text>
        {isMediumScreen ? (
          <>
        <Text pt='2' fontSize='xl'>
        Taip pat prisekite iki 10 nuotraukų, kurios man padėtų geriau suprasti jūsų poreikį ir kaip jį atliepti.
Visada naudokite kuo naujesnes nuotraukas: bent vieną visu ūgiu, kurioje aiškiai matosi jūsų
sudėjimas ir kūno linijos, proporcijos ir panašiai. Portretą, kuriame matosi jūsų akių, plaukų spalva,
oda ir bendrai jūsų bruožai. Tokios nuotraukas padeda prireikus pasiūlyti ir įvaizdį papildančią
makiažo, kirpimo ar šukuosenos idėją.
</Text>
          </>
        ) : null}
{isLargeScreen ? (
        <>
<Text pt='2' fontSize='xl'>
Dar prisekite keletą nuotraukų, kuriose gerai matosi, kaip
jūs įprastai rengiatės arba derinio išbaigimo atveju parodykite visus jau turimus įvaizdžio
elementus (suknelę, batelius, makiažo idėją ir panašiai). Ir bendrai dar prisekite nuotraukų savo
nuožiūra - ką būtinai turėčiau suprasti ir žinoti apie jūsų stiliaus poreikį prieš rašydama jums
atsakymą.
</Text>
<Text pt='2' fontSize='xl'>
Į visas jūsų užklausas atsakau asmeniškai pati, todėl rašydami galite būti tikri - bendraujate
tiesiogiai su manimi, Vilija Rušinskaite.
</Text>
        <Text pt='2' fontSize='xl'>
        Užklausas atsakysiu per 2 darbo dienas. 
        </Text>
        <Text pt='2' fontSize='xl'>
        Norėjau sukurti tokią asmeninio stiliaus konsultacijų terpę, kurioje man užtektų laiko įsigilinti į
klausimus ir gerai apgalvoti atsakymus. Kurioje nebūtų automatiškai siuntinėjami iš anksto
paruošti šabloniniai, visiems ir tuo pačiu niekam tinkantys atsakymai ir patarimai. Todėl kol
atsakinėju asmeniškai ir viena - trumpųjų, super greitų užklausų pasirinkimo nebus. O bus tik
dėmesingas ir apgalvotas konsultavimas labai žmogišku tempu.
        </Text>
        <Text pt='2' fontSize='xl'>
        Taip pat prie atsakymų kol kas nebus segamos drabužių ir aksesuarų įsigijimo nuorodos.
Atsakymai yra rekomendacinio pobūdžio, nukreipiantys jus reikiama linkme su vizualiniais
pavyzdžiais ir praktiniais patarimais.
        </Text>
        <Text pt='2' fontSize='xl'>
        Su kiekvienu klientu bendrauju remdamasi visiško priėmimo ir nulinio smerkimo dėl gyvenimo
pasirinkimų ar išvaizdos principu. Tad tikiuosi, kad su manimi bendrausite be įtampos ir baimės
kažkuo neįtikti. Visi esame vienodai verti stiliaus patarimų ir pozityvaus paskatinimo būti savimi.
Tikiuosi, kad su laiku &quot;ką rengtis&quot; taps palaikančia ir draugiška bendruomene.
        </Text>
        </> ) : (
          <Link to="/kaip-tai-veikia"> {/* Adjust the path to your desired route */}
          <Text pt='2' fontSize='xl' color='#FB8FFF' cursor='pointer' textAlign='right'>
            Skaityti toliau <Icon as={ChevronRightIcon} />
          </Text>
        </Link>
        )}
      </Box>
  </CardBody>
  </Stack >
  <Image
      objectFit='cover'
      objectPosition='50% 50%'
      height="63vh"
      srcSet="
        vilija2_small.jpg 480w,
        vilija2_medium.jpg 800w,
        vilija2_large.jpg 1200w
      "
      sizes="(max-width: 600px) 100vw, (max-width: 1200px) 50vw, 600px"
      alt="stilistė Vilija Rušinskaitė" 
    maxW={{ base: '100%', sm: '400px' }}
     src="_E1A5506.JPG" //Falback image
     display={{ base: 'none', md: 'none', lg: 'block' }} // Hide on small and medium screens
     />
  </Card>
  </Center>
  <Box minH='2vw' />
  <Center>
  <Card border='1px' maxWidth="80vw" minWidth='60vw'  direction={{ base: 'column', sm: 'row' }}
  overflow='hidden'>
  <Stack >
  <Center>
  <Image objectFit='cover'
    objectPosition='100% 100%'
    maxW={{ base: '100%', sm: '600px' }}
    width='50vw'
    // height="58.5vh"
    height="66vh"
    srcSet="
      vilija3_small.jpg 480w,
      vilija3_medium.jpg 800w,
      vilija3_large.jpg 1200w
    "
    sizes="(max-width: 600px) 100vw, (max-width: 1200px) 50vw, 600px"
    display={isMediumScreen ? { base: 'none', md: 'none', lg: 'block' } : { base: 'none', md: 'none', lg: 'none' }} // Hide on small and medium screens, show on large
    src="_E1A5394.JPG" alt="stiliste filantrope" />
  </Center>
  </Stack>
  <Stack spacing={0}>
  <CardHeader pb={0}>
  <Box minH='1vw' />
    <Center>
    <Heading sx={{ fontSize: ['24px', '32px', '32px', '32px'], fontFamily: 'Montserrat, sans-serif',
      textAlign: 'center'
    }}>Kaip aš rengiu ir stilizuoju</Heading>
    </Center>
  </CardHeader>
  <CardBody sx={{pt: ['8px', '8px','8px','20px']}}>
      <Box>
        <Text pt='0' sx={{ fontSize: ['ml', 'xl', 'xl', 'xl']}}> 
        Per 10 metų savo darbe pastebėjau daug kartų pasitvirtinusią taisyklę: visi žmonės jaučiasi geriau,
užtikrinčiau ir energingiau bei įgauna stipresnį emocinį tonusą, kai jiems patinka tai, kaip jie atrodo
ir kai tai nebūna vienkartinis derinys, o naujas, įgalinantis požiūris be streso į savo stilių. O tada tai
labai greitai pajaučia ir teigiamai atliepia aplinkiniai, ir taip užsisuka pozityvus ratas. Bet vieno
universalaus recepto, patarimo ar pirkinių sąrašo, pritaikomo kiekvienam, tiesiog nėra. Juk
skirtingi esame ne tik amžiumi, ūgiu, kūno proporcijomis, bet ir emocine būkle, dabartiniais
gyvenimo tikslais, gyvenimo būdo intensyvumu, energijos lygiu, pomėgiais, kaip ir kur leidžiame
didžiąją dalį savo dienos laiko ir net tokiu kasdieniu kriterijumi kaip mėgstamos parduotuvės.
Todėl su kiekvienu žmogumi aš pirma bandau išsiaiškinti vidinį ir išorinį, dabartinei situacijai
aktualų poreikį. Kuo tiksliau man nupasakosite ko jums reikia, kas jums patinka, kuo dabar
gyvenate, o gal kaip tik - kas jums itin nepatinka ir yra nepriimtina, tuo lengviau padėsiu jums
pasiekti džiuginantį rezultatą. 
        </Text>
        {isLargeScreen ? (
        <>
        <Text pt='2' fontSize='xl'>
        Auksinės asmeninio stiliaus taisyklės vis dar egzistuoja ir gali būti naudingos kiekvienam derinant
proporcijas, ilgius, ieškant tinkamesnės drabužio struktūros ar tinkančių spalvų asmeniškai jums,
bet jos nėra viskas. Per laiką išlavinant pajautimą, matymą bei supratimą tiek apie tai, kas jums
asmeniškai tinka - nuo spalvų, ilgių, tekstūrų iki siluetų - tiek ir apie pačią madą, jos laikmečio
dvasią, aktualius prekinius mados ženklus ir įtakingesnius, laikui mažiau pavaldžius dizainus, tas
taisykles galima pradėti geriau pritaikyti sau, performuoti pagal poreikį arba apskritai jas laužyti bei
plėsti jų ribas ir prasmes.
        </Text>
        <Text pt='2' fontSize='xl'>
        Neturint laiko šiai savišvietai gaukite trumpus, personalizuotus stiliaus patarimus, kuriuos jums
rašau pasitelkdama visą savo dešimtmečio patirtį, sukauptą tiek stilizuojant asmeninius klientus -
nuo politikų, iki startuolių įkūrėjų, rašytojų, verslininkų, vestuvių planuotojų, mokslininkų, mamų ir
tėčių, peržiūrint jų spintas ir asmeninių apsipirkimų metu tikslingai jas papildant, tiek dirbant su
komerciniais projektais, žurnalų fotosesijomis, įvairiais Lietuvos mados dizaineriais ir inovatoriais.
        </Text>
        <Text pt='2' fontSize='xl'>
        Kuo aš remiuosi stilizuodama ir konsultuodama klientus? Man patinka dabar vyraujanti
individualistiško ir tuo kiekvienam autentiško stiliaus kultūra. Ji turi daugiausiai prasmės - visi mes
skirtingi, tai kodėl turėtume atrodyti vienodai ar aklai susipirkinėti identiškas kapsulines spintas?
Šia losoja vadovaudamasi su kiekvienu klientu bendrauju pagal individualų poreikį, dėmesingai
ir tikslingai. Ir niekada nesiūlau išsiskirti iš minios vien dėl sportinio intereso, nes visų pirma -
asmeninis stilius turi atliepti jūsų asmeninius interesus ir padėti siekti užsibrėžtų tikslų, kad ir kokie
jie šiuo metu būtų. O antra - dirbimas minios &quot;įdomybių&quot; ir personažų alkio patenkinimui yra
varginantis ir neapdovanojantis, jeigu iš prigimties nesate ekscentriška ir ekstravertiška asmenybė.
Stilius, kaip ir žmonių asmenybės, yra ir turi būti visoks. Tegu jis būna apie jus.
        </Text>
        </>
      ) : (
        <Link to="/kaip-as-stilizuoju"> {/* Adjust the path to your desired route */}
        <Text pt='2' fontSize='xl' color='#FB8FFF' cursor='pointer' textAlign='right'>
          Skaityti toliau <Icon as={ChevronRightIcon} />
        </Text>
        </Link>
      )}
      </Box>
  </CardBody>
  </Stack>
  </Card>
  </Center>
  <Box minH='2vw'/>
  </>
  );
}